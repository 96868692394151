import { useState, useEffect } from "react";
import styles from "./style.module.scss";
import NewsCard, { NewsCardData } from "src/components/news-card/NewsCard";
import Button from "src/components/common/button/Button";
import Header from "src/components/common/header/Header";
import SwiperComponent from "src/components/common/swiper/SwiperComponent";
import axios from "axios";
import { authToken, headers } from "src/helper/authToken";
import { useLocation, useNavigate } from "react-router-dom";
import { formatDate } from "src/helper/formatDate";
import { useLoader } from "src/context/LoaderContext";
import Loader from "src/components/common/loader/Loader";
import { getSessionKey } from "src/helper/sessionServices";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

function chipGeneration(tag: string) {
  let resultTag = "";
  switch (tag) {
    case "Leading":
      resultTag = "leading";
      break;
    case "Highlight":
      resultTag = "highlight";
      break;
    case "Latest":
      resultTag = "latest";
      break;
    case "In Focus":
      resultTag = "focus";
      break;
    case "Trending":
      resultTag = "trending";
      break;
    default:
      resultTag = "";
      break;
  }
  return resultTag;
}

function NewsDetail() {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation(["Home"]);
  const { loading, setLoading } = useLoader();
  const [newsCards, setNewsCards] = useState<NewsCardData[]>([]);
  const [newsDetail, setNewsDetail] = useState<NewsCardData>();
  //const history = useHistory()
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const newsId = searchParams.get("newcode");
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}auth/news?lang_id=${
          getSessionKey("userData")?.lang_id || getSessionKey("selectedLang")
        }`,
        {
          headers: {
            Authorization: `Bearer ${
              getSessionKey("authToken") !== null
                ? getSessionKey("authToken").accesstoken
                : ""
            }`,
          },
        }
      )
      .then((response) => {
        setNewsCards(response?.data?.data);
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          navigate("/enter-pin");
        } else {
          console.error("Error fetching data:", error.message);
        }
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}auth/news?newcode=${newsId}&lang_id=${
          getSessionKey("userData")?.lang_id || getSessionKey("selectedLang")
        }`,
        {
          headers: {
            Authorization: `Bearer ${
              getSessionKey("authToken") !== null
                ? getSessionKey("authToken").accesstoken
                : ""
            }`,
          },
        }
      )
      .then((response) => {
        setNewsDetail(response?.data?.data[0]);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 403 || error.response.status === 401) {
          navigate("/enter-pin");
        } else {
          console.error("Error fetching data:", error.message);
        }
      });
  }, [newsId]);

  const getDownloadObj = {
    link: newsDetail?.link,
  };

  return (
    <>
      {loading ? <Loader /> : null}
      <header>
        <Header
          backLink={true}
          actionItems={["share"]}
          detailData={getDownloadObj}
        ></Header>
      </header>
      <main>
        <div className="lyt-main typ-newsdetail">
          <section>
            <div className="bs-section">
              <div className="sec-cont">
                <div className={`${styles.newsDetailWrap}`}>
                  <div className={`${styles.imgWrap}`}>
                    <img src={`${newsDetail?.imgUrl}`} alt="" />
                    <span
                      className={`${styles.tag} cm-tag typ-${chipGeneration(
                        newsDetail?.chip!
                      )}`}
                    >
                      {newsDetail?.chip}
                    </span>
                  </div>
                  <div className={`${styles.contentWrap}`}>
                    <div className={`${styles.contHead}`}>
                      <h1 className={`${styles.title}`}>{newsDetail?.title}</h1>
                      <span className={`${styles.date}`}>
                        {formatDate(newsDetail?.Newdate!)}
                      </span>
                    </div>
                    <div
                      className="cont-body"
                      dangerouslySetInnerHTML={{
                        __html: newsDetail?.description || "",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {newsCards && newsCards.length > 1 ? (
            <section>
              <div className="bs-section typ-news">
                <div className="sec-head">
                  <h2 className="cm-sec-title typ-sm">{t("home_moreNews")}</h2>
                </div>
                <div className="sec-cont">
                  <div className="bs-swiper typ-news">
                    <SwiperComponent
                      swiperConfig={{
                        slidesPerView: "auto",
                        spaceBetween: 12,
                        observer: true,
                      }}
                    >
                      {newsCards
                        ?.filter((item) => item.newcode != newsId)
                        .map((item, index) => {
                          return (
                            <NewsCard
                              key={index}
                              {...item}
                              smallCard={true}
                            ></NewsCard>
                          );
                        })}
                    </SwiperComponent>
                    <div className={`${styles.actWrap} act-wrap`}>
                      <Button
                        btnStyle="text"
                        navigation={true}
                        navigationUrl="/news-list"
                      >
                        <span>{t("home_viewAllBtn")}</span>
                        <i className="icon-next"></i>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          ) : null}
        </div>
      </main>
    </>
  );
}

export default NewsDetail;
