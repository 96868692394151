import OtpInput from "react-otp-input";
import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
import Button from "src/components/common/button/Button";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "src/components/common/header/Header";
import axios from "axios";
import { getSessionKey } from "src/helper/sessionServices";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { setSessionKey } from "src/helper/sessionServices";
import { useLoader } from "src/context/LoaderContext";
export const StepContext = React.createContext<{
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
} | null>(null);

function CreatePin() {
  const { i18n, t } = useTranslation(["ProfileSettings"]);
  const lang = getSessionKey("selectedLangCode");
  const { userData, setUserData } = useLoader();
  const navigate = useNavigate();
  const [btnValid, setBtnValid] = useState(false);
  const [showError, setShowError] = useState(false);
  const [otp, setOtp] = useState("");
  const [confirmOtp, setConfirmOtp] = useState("");
  const location = useLocation();
  const data: any = location.state;
  useEffect(() => {
    i18next.changeLanguage(lang);
  }, []);
  useEffect(() => {
    setBtnValid(otp === confirmOtp && otp.length === 4);
  }, [otp, confirmOtp]);

  const handleSkipClick = () => {
    setUserData((prev: any) => ({ ...prev, isSetPinSkip: true }));
    navigate("/profile-update/add-profile-picture");
  };

  const handleClick = () => {
    //navigate("/profile-update/add-profile-picture");
    if (data !== null) {
      if (data.hasOwnProperty("source")) {
        if (data.source === "forgot-pin") {
          const payload = {
            pin: confirmOtp,
            email: data.email, // current user id
          };
          axios
            .patch(`${process.env.REACT_APP_API_URL}auth/forgotPin`, payload)
            .then((res) => {
              // console.log('res', res);
              if (res.data.success) {
                navigate("/enter-pin");
              } else {
                setShowError(true);
              }
            })
            .catch((error) => {
              //formik.errors.email = error.message
              setShowError(true);
              console.error("Error fetching data:", error.message);
            });
        } else {
          const userData = getSessionKey("userData");
          const payload = {
            type: "set", // type :- [set,update]
            pin: confirmOtp,
            id: userData.id, // current user id
          };
          axios
            .patch(`${process.env.REACT_APP_API_URL}pin`, payload, {
              headers: {
                Authorization: `Bearer ${
                  getSessionKey("authToken").accesstoken
                }`,
              },
            })
            .then((res) => {
              if (res.data.success) {
                navigate("/profile-update/add-profile-picture");
                setShowError(false);
                const userData = getSessionKey("userData");
                userData.pin = confirmOtp;
                setUserData(userData);
                setSessionKey("userData", userData);
              } else {
                setShowError(true);
              }
            })
            .catch((error) => {
              //formik.errors.email = error.message
              setShowError(true);
              if (
                error.response.status === 403 ||
                error.response.status === 401
              ) {
                navigate("/enter-pin");
              } else {
                console.error("Error fetching data:", error.message);
              }
            });
        }
      }
    } else {
      const userData = getSessionKey("userData");
      const payload = {
        type: "set", // type :- [set,update]
        pin: confirmOtp,
        id: userData.id, // current user id
      };
      axios
        .patch(`${process.env.REACT_APP_API_URL}pin`, payload, {
          headers: {
            Authorization: `Bearer ${getSessionKey("authToken").accesstoken}`,
          },
        })
        .then((res) => {
          if (res.data.success) {
            navigate("/profile-update/add-profile-picture");
            setShowError(false);
            const userData = getSessionKey("userData");
            userData.pin = confirmOtp;
            setUserData(userData);
            setSessionKey("userData", userData);
          } else {
            setShowError(true);
          }
        })
        .catch((error) => {
          setShowError(true);
          if (error.response.status === 403 || error.response.status === 401) {
            navigate("/enter-pin");
          } else {
            console.error("Error fetching data:", error.message);
          }
        });
    }
  };

  const [step, setStep] = useState(1);
  return (
    <StepContext.Provider value={{ step, setStep }}>
      <header>
        <Header
          backLink={false}
          showProgress={true}
          currentStep={step}
          totalSteps={2}
        ></Header>
      </header>

      <div className="lyt-main">
        <div className={`${styles.section}`}>
          <div className={`${styles.sectionHead}`}>
            <div className={`${styles.titleWrap}`}>
              <h1 className={`${styles.pgTitle}`}>
                {t("profilesettings_createPinPgTitle")}
              </h1>
            </div>
            <p className={`${styles.subTitle}`}>
              {t("profilesettings_createPinPgSubTitle")}
            </p>
          </div>
          <div className="bs-form typ-pin">
            <form>
              <div className="form-body">
                <div className="form-group">
                  <div className={`${showError ? "invalid" : ""} otp-wrap`}>
                    <label htmlFor="otp">
                      {t("profilesettings_createNewPin")}
                    </label>
                    <OtpInput
                      // id="otpPin"
                      // name="otpPin"
                      value={otp}
                      onChange={setOtp}
                      numInputs={4}
                      inputType="number"
                      renderInput={(props) => <input {...props} required />}
                    />
                    <label htmlFor="confirmOtp">
                      {t("profilesettings_confirmNewPin")}
                    </label>
                    <OtpInput
                      value={confirmOtp}
                      onChange={setConfirmOtp}
                      numInputs={4}
                      inputType="number"
                      renderInput={(props) => <input {...props} required />}
                    />
                    {showError && (
                      <span className="MuiFormHelperText-root Mui-error">
                        {t("profilesettings_incorrectPin")}
                      </span>
                    )}
                    {otp !== confirmOtp && confirmOtp.length === 4 && (
                      <span className="MuiFormHelperText-root Mui-error">
                        {t("profilesettings_validationPin")}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-foot">
                <Button
                  btnStyle="primary"
                  type="button"
                  onClick={handleClick}
                  disabled={!btnValid}
                >
                  {t("profilesettings_createPinBtn")}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </StepContext.Provider>
  );
}

export default CreatePin;
