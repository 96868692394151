import Header from "src/components/common/header/Header";
import styles from "./style.module.scss";
import Button from "src/components/common/button/Button";
import { TextField } from "@mui/material";
import BottomSheet from "src/components/bottom-sheet/BottomSheet";
import { useEffect, useState } from "react";
import { getSessionKey } from "src/helper/sessionServices";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
let confirmClientData: any = [];
function ConfirmClient() {
  const { i18n, t } = useTranslation(["Advisor"]);
  const lang = getSessionKey("selectedLangCode");
  const navigate = useNavigate();
  confirmClientData = [getSessionKey("addClientObj")];
  console.log(Object.keys(confirmClientData));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSubmit = () => {
    const payload: any = [];
    confirmClientData[0].map((item: any, index: any) => {
      payload.push({
        email: item,
        relationshipType: 0,
      });
    });

    axios
      .post(`${process.env.REACT_APP_API_URL}relation`, payload, {
        headers: {
          Authorization: `Bearer ${getSessionKey("authToken").accesstoken}`,
        },
      })
      .then((res) => {
        let historyData = {
          reference: "relation added",
        };
        navigate("/acknowledgement", { state: historyData });
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          navigate("/enter-pin");
        } else {
          console.error("Error fetching data:", error.message);
        }
      });
  };
  useEffect(() => {
    i18next.changeLanguage(lang);
  }, []);
  return (
    <>
      <header>
        <Header backLink={true}></Header>
      </header>
      <main>
        <div className="lyt-main typ-details-form">
          <div className={`${styles.section}`}>
            <div className={`${styles.sectionHead}`}>
              <h1 className={`${styles.pgTitle}`}>
                {t("advisor_confirmClientTitle")}
              </h1>
              <p className={`${styles.description}`}>
                {t("advisor_confirmClientSubTitle")}
              </p>
            </div>
            <div className="bs-confirmation">
              <div className="cont">
                <ul className="list">
                  {confirmClientData[0].map((item: any, index: any) => {
                    return (
                      <li className="item" key={index}>
                        <div className="bs-form">
                          <div className="form-group">
                            <TextField
                              value={item}
                              label={
                                `${t("advisor_addClient")} #` + (index + 1)
                              }
                              InputProps={{ readOnly: true }}
                            ></TextField>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className="form-foot">
              <Button btnStyle="primary" onClick={handleSubmit}>
                {t("advisor_confirmBtn")}
              </Button>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default ConfirmClient;
