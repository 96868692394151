import { FormControl, TextField } from "@mui/material";
import styles from "./style.module.scss";
import Button from "src/components/common/button/Button";
import PhoneNumber from "src/components/phone-number/PhoneNumber";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { getSessionKey, setSessionKey } from "src/helper/sessionServices";
import { useEffect } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import Header from "../common/header/Header";
export interface Values {
  countryCode: string;
  phn: string;
}

function UpdateEmailDetails() {
  const { i18n, t } = useTranslation(["PersonalDetails"]);
  const navigate = useNavigate();
  const settingType = getSessionKey("settingType");
  const validationSchema = yup.object({
    email: yup
      .string()
      .email(`${t("personaldetails_validationEmail")}`)
      .required(`${t("personaldetails_validationEmailRequired")}`),
  });
  const otpData = {
    type: "email",
    iconName: "email",
    value: "abc@abc.com",
    source: "personal-detail-setting",
    userexists: false,
  };
  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      otpData.value = values.email;
      const otpPayload = {
        email: values.email,
        sendby: "email",
        type: "verification",
      };
      axios
        .post(`${process.env.REACT_APP_API_URL}auth/otp`, otpPayload)
        .then((otpRes) => {
          if (otpRes.data.success) {
            navigate("/otp", { state: otpData });
          } else {
            formik.errors.email = otpRes.data.error;
          }
        })
        .catch((error) => {
          if (error.response.status === 403 || error.response.status === 401) {
            navigate("/enter-pin");
          } else {
            console.error("Error fetching data:", error.message);
            formik.errors.email = error.response.data.error;
          }
        });
    },
  });

  return (
    <>
      <header>
        <Header backLink={true}></Header>
      </header>
      <div className="lyt-main typ-details-form">
        <div className={`${styles.section}`}>
          <div className={`${styles.sectionHead}`}>
            <h1 className={`${styles.pgTitle}`}>
              {t("personaldetails_title")}
            </h1>
          </div>
          <div className="bs-form">
            <form onSubmit={formik.handleSubmit}>
              <div className="form-body">
                <div className="form-group"></div>
                <div className="form-group">
                  <TextField
                    id="email"
                    name="email"
                    label="Email"
                    placeholder="Enter Email Id"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  ></TextField>
                </div>
              </div>
              <div className="form-foot">
                <Button
                  btnStyle="primary"
                  type="submit"
                  disabled={!formik.isValid}
                >
                  {t("personaldetails_proceedBtn")}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default UpdateEmailDetails;
