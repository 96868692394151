import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import styles from "./style.module.scss";
import Button from "src/components/common/button/Button";
import AddUbo from "src/components/add-ubo/AddUbo";
import { useContext, useEffect, useState } from "react";
import { getSessionKey, setSessionKey } from "src/helper/sessionServices";
import {
  DocumentDataType,
  IdentityProofObj,
} from "../../common/identity-proof/IdentityProof";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { StepContext } from "../../DetailsForm";
import Loader from "src/components/common/loader/Loader";
import { useLoader } from "src/context/LoaderContext";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

export interface PropsDataType {
  currentStep: number;
}
export interface BaseStructureUBODataType {
  id: number | string | null;
  UBOsCardId: string;
  userid: number | null;
  userdetailid: number | null;
  firstname: string;
  lastname: string;
  countrycode: string;
  contact: number | null;
  detailtype: string;
  isFilled: boolean;
  document: [
    {
      id: number | string | null;
      fieldId: string;
      userid: number | null;
      userdetailid: number | null;
      documenturl: string;
      documenttype: string;
      documentsubtype: string;
      expirydate: string;
      documentnumber: string;
      filetype: string;
      fileName: string;
      status?: string;
    },
    {
      id: number | string | null;
      fieldId: string;
      userid: number | null;
      userdetailid: number | null;
      documenturl: string;
      documenttype: string;
      documentsubtype: string;
      isResidence: boolean | null;
      country: string;
      provience: string;
      zipcode: number | null;
      city: string;
      street: string;
      housenumber: string;
      documentdetail: string;
      fileType: string;
      fileName: string;
      expirydate: string;
      region: string;
      status?: string;
    },
    {
      id: number | string | null;
      fieldId: string;
      userid: number | null;
      userdetailid: number | null;
      documenturl: string;
      documenttype: string;
      fileType: string;
      fileName: string;
      status?: string;
    }
  ];
}

function UboDetails(props: PropsDataType) {
  const { i18n, t } = useTranslation(["CompanyDetails"]);
  const lang = getSessionKey("selectedLangCode");
  useEffect(() => {
    i18next.changeLanguage(lang);
  }, []);
  const { loading, setLoading } = useLoader();
  const { step, setStep } = useContext(StepContext)!;
  const baseStructureUBO: BaseStructureUBODataType = {
    id: null,
    UBOsCardId: "",
    userid: getSessionKey("authToken") ? getSessionKey("authToken")?.id : "",
    userdetailid: getSessionKey("authToken")
      ? getSessionKey("authToken")?.userdetail !== undefined
        ? getSessionKey("authToken").userdetail.id
        : getSessionKey("authToken").userDetailId
      : "",
    firstname: "",
    lastname: "",
    countrycode: "",
    contact: null,
    detailtype: "ubo",
    isFilled: false,
    document: [
      {
        id: null,
        fieldId: "idProof",
        userid: getSessionKey("authToken")?.id,
        userdetailid:
          getSessionKey("authToken")?.userdetail !== undefined
            ? getSessionKey("authToken")?.userdetail.id
            : getSessionKey("authToken").userDetailId,
        documenturl: "",
        documenttype: "Identity Proof",
        documentsubtype: "",
        expirydate: "",
        documentnumber: "",
        filetype: "",
        fileName: "",
      },
      {
        id: null,
        fieldId: "residenceProof",
        userid: getSessionKey("authToken")?.id,
        userdetailid:
          getSessionKey("authToken")?.userdetail !== undefined
            ? getSessionKey("authToken")?.userdetail.id
            : getSessionKey("authToken").userDetailId,
        documenturl: "",
        documenttype: "Residence Proof",
        documentsubtype: "",
        isResidence: null,
        country: "",
        provience: "",
        zipcode: null,
        city: "",
        region: "",
        expirydate: "",
        street: "",
        housenumber: "",
        documentdetail: "Address Proof",
        fileType: "",
        fileName: "",
      },
      {
        id: null,
        fieldId: "shareholdingRegister",
        userid: getSessionKey("authToken")?.id,
        userdetailid:
          getSessionKey("authToken")?.userdetail !== undefined
            ? getSessionKey("authToken")?.userdetail?.id
            : getSessionKey("authToken")?.userDetailId,
        documenturl: "",
        documenttype: "Share holding Registry",
        fileType: "",
        fileName: "",
      },
    ],
  };

  const filterItemsByType = (data: any, type: string) => {
    return data[0].userdetail.userlrdetail.filter(
      (item: any) => item.detailtype === type
    );
  };

  const getNoOfUBOs = () => {
    let tempUserDetails = getSessionKey("uboDetails")
      ? getSessionKey("uboDetails")
      : getSessionKey("userExistData");
    return getSessionKey("companyDetails") !== null
      ? getSessionKey("companyDetails")?.noOfUBOs
      : filterItemsByType(tempUserDetails, "ubo").length;
  };

  const navigate = useNavigate();
  const [noOfUBOs, setNoOfUBOs] = useState(getNoOfUBOs());
  const [prevNoOfUBOs, setPrevNoOfUBOs] = useState(0);
  const [uboStructures, setUboStructures] = useState<
    BaseStructureUBODataType[]
  >([]);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [userDetails, setUserDetails] = useState<any>(null);

  useEffect(() => {
    if (prevNoOfUBOs === 0) {
      setUboStructures([]);
      let tempArr = [];
      for (let i = 0; i < noOfUBOs; i++) {
        baseStructureUBO["UBOsCardId"] = `UBO_${i}`;
        tempArr.push({ ...baseStructureUBO });
      }
      setUboStructures(tempArr);
    } else if (noOfUBOs < prevNoOfUBOs) {
      let diff = noOfUBOs - prevNoOfUBOs;
      setUboStructures((prevItems) => {
        // Use slice to create a new array without the last item
        const updatedItems = prevItems.slice(0, diff);
        // Return the updated array
        return updatedItems;
      });
    } else if (noOfUBOs > prevNoOfUBOs) {
      let diff = noOfUBOs - prevNoOfUBOs;
      let lastCardId = Number(
        uboStructures[uboStructures.length - 1]["UBOsCardId"].split("_")[1]
      );
      let tempArr = [];
      for (let i = 0; i < diff; i++) {
        lastCardId += 1;
        baseStructureUBO["UBOsCardId"] = `UBO_${lastCardId}`;
        tempArr.push({ ...baseStructureUBO });
      }
      let newArray = [...uboStructures, ...tempArr];
      setUboStructures(newArray);
    }
    if (!isEdit) {
      let tempUserDetails: any;
      let userDetails: any;
      tempUserDetails = getSessionKey("uboDetails")
        ? getSessionKey("uboDetails")
        : getSessionKey("userExistData");

      if (tempUserDetails !== null) {
        if (
          (getSessionKey("userRegStatus").toLowerCase() === "declined" ||
            getSessionKey("userRegStatus").toLowerCase() ===
              "document approval pending" ||
            getSessionKey("settingType") === "UBO") &&
          getSessionKey("uboDetails") === null
        ) {
          userDetails = filterItemsByType(tempUserDetails, "ubo");
        } else {
          userDetails = tempUserDetails;
        }
        setUserDetails(userDetails);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noOfUBOs]);

  const handleChange = (e: any) => {
    setPrevNoOfUBOs(noOfUBOs);
    setNoOfUBOs(e.target.value);
  };

  const postUBODetails = () => {
    setLoading(true);
    if (isEdit && getSessionKey("settingType") === "UBO") {
      axios
        .put(`${process.env.REACT_APP_API_URL}lrdetail`, uboStructures, {
          headers: {
            Authorization: `Bearer ${getSessionKey("authToken").accesstoken}`,
          },
        })
        .then((res) => {
          if (res.data.success) {
            setSessionKey("uboDetails", JSON.stringify(uboStructures));
            setLoading(false);
            if (getSessionKey("settingType") === "UBO")
              navigate("/client-approve");
            else navigate("/signup/details-form/legal-representative-details");
          }
        })
        .catch((error) => {
          setLoading(false);
          if (error.response.status === 403 || error.response.status === 401) {
            navigate("/enter-pin");
          } else {
            console.error("Error fetching data:", error.message);
          }
        });
    } else {
      setSessionKey("uboDetails", JSON.stringify(uboStructures));
      setLoading(false);
      navigate("/signup/details-form/legal-representative-details");
    }
  };

  useEffect(() => {
    setStep(props.currentStep!);
  }, []);

  useEffect(() => {
    if (userDetails !== null) {
      console.log("UBO-Details!!!-->", userDetails);
      userDetails.forEach((item: any, index: number) => {
        setUboStructures((prevArray: any) => {
          const newState = prevArray.map((obj: any, idx: number) => {
            if (idx === index) {
              console.log("item", item);
              return {
                ...obj,
                id: item.id,
                firstname: item.firstname,
                lastname: item.lastname,
                countrycode: item.countrycode,
                contact: item.contact,
                detailtype: "ubo",
                isFilled: true,
                document: obj.document.map((doc: any) => {
                  if (doc.fieldId === "idProof") {
                    return {
                      ...doc,
                      id: item?.document[0]?.id,
                      documenturl: item?.document[0]?.documenturl,
                      documentsubtype: item?.document[0]?.documentsubtype,
                      expirydate: item?.document[0]?.expirydate,
                      documentnumber: item?.document[0]?.documentnumber,
                      filetype: item?.document[0]?.fileType,
                      fileName: item?.document[0]?.fileName,
                    };
                  } else if (doc.fieldId === "residenceProof") {
                    return {
                      ...doc,
                      id: item?.document[1]?.id,
                      documenturl: item?.document[1]?.documenturl,
                      documentsubtype: item?.document[1]?.documentsubtype,
                      country: item?.document[1]?.country,
                      provience: item?.document[1]?.provience,
                      zipcode: item?.document[1]?.zipcode,
                      city: item?.document[1]?.city,
                      street: item?.document[1]?.street,
                      housenumber: item?.document[1]?.housenumber,
                      fileType: item?.document[1]?.fileType,
                      fileName: item?.document[1]?.fileName,
                      expirydate: item?.document[1]?.expirydate,
                      region: item?.document[1]?.region,
                    };
                  } else if (doc.fieldId === "shareholdingRegister") {
                    return {
                      ...doc,
                      id: item?.document[2]?.id,
                      documenturl: item?.document[2]?.documenturl,
                      fileType: item?.document[2]?.fileType,
                      fileName: item?.document[2]?.fileName,
                    };
                  }
                  return doc;
                }),
              };
            } else {
              return obj;
            }
          });
          return newState;
        });
      });
      setIsEdit(true);
    }
  }, [userDetails]);

  useEffect(() => {
    console.log(uboStructures, "UBO Struecture");
  }, [userDetails]);

  const isValid = () => {
    let temp = false;
    setTimeout(() => {
      temp = uboStructures.every((obj) => obj.isFilled);
    }, 1500);
    return temp;
  };

  return (
    <>
      {loading ? <Loader /> : <></>}
      <div className="lyt-main typ-details-form">
        <div className={`${styles.section}`}>
          <div className={`${styles.sectionHead}`}>
            <h1 className={`${styles.pgTitle}`}>
              {t("companydetails_uboDetailsTitle")}
            </h1>
          </div>
          <div className="bs-form">
            <form>
              <div className="form-body">
                <div className="form-section">
                  <h3 className="frm-sec-title">
                    {t("companydetails_enterTitle")}
                  </h3>
                  <div className="form-group">
                    <div className="bs-select">
                      <FormControl fullWidth>
                        <InputLabel className="input-label">
                          {t("companydetails_numberOfUbo")}
                        </InputLabel>
                        <Select
                          id="ubo"
                          value={noOfUBOs}
                          label={t("companydetails_numberOfUbo")}
                          onChange={handleChange}
                        >
                          <MenuItem value={1}>1</MenuItem>
                          <MenuItem value={2}>2</MenuItem>
                          <MenuItem value={3}>3</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </div>
                <div className={`${styles.cardList}`}>
                  {uboStructures.map((item, index) => {
                    return (
                      <div key={index} className={`${styles.cardItem}`}>
                        <AddUbo
                          {...item}
                          cardIndex={index + 1}
                          isEdit={
                            uboStructures[index]?.document?.length !== null
                              ? isEdit
                              : false
                          }
                          // postUBODetails={getUBODetails}
                          setUboStructuresState={setUboStructures}
                        ></AddUbo>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="form-foot">
                <Button
                  btnStyle="primary"
                  type="button"
                  disabled={!isValid}
                  onClick={postUBODetails}
                >
                  {t("companydetails_proceedBtn")}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default UboDetails;
