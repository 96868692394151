import Header from "src/components/common/header/Header";
import styles from "./style.module.scss";
import { FormControl, TextField } from "@mui/material";
import Button from "src/components/common/button/Button";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import SuccessError from "src/components/success-error/SuccessError";
import axios from "axios";
import {
  getSessionKey,
  isLoggedIn,
  setSessionKey,
} from "src/helper/sessionServices";
import { useEffect, useState } from "react";
import { useLoader } from "src/context/LoaderContext";
import Loader from "src/components/common/loader/Loader";
import CheckBox from "src/components/common/checkbox/CheckBox";
import BottomSheet from "src/components/bottom-sheet/BottomSheet";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

function Login() {
  const { i18n, t } = useTranslation(["Login"]);
  const lang = getSessionKey("selectedLangCode");

  const validationSchema = yup.object({
    email: yup
      .string()
      .email(`${t("login_validationEmail")}`)
      .matches(
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
        `${t("login_validationEmail")}`
      )
      .required(`${t("login_validationEmailRequired")}`),
  });

  const [openNoteDrawer, setOpenNoteDrawer] = useState(true);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  const handleCheckboxChange = (event: any) => {
    event.target.checked
      ? setIsCheckboxChecked(true)
      : setIsCheckboxChecked(false);
  };

  const handleButtonClick = () => {
    setOpenNoteDrawer(false);
  };
  const { loading, setLoading } = useLoader();
  const navigate = useNavigate();
  const otpData = {
    type: "email",
    iconName: "email",
    value: "abc@abc.com",
    source: "login",
    userexists: false,
  };
  useEffect(() => {
    if (isLoggedIn()) navigate("/impact");
    i18next.changeLanguage(lang);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      otpData.value = formik.values.email;
      axios
        .post(`${process.env.REACT_APP_API_URL}auth/checkusers`, {
          email: formik.values.email,
        })
        .then((response) => {
          if (response.data.success) {
            if (response.data.data.userexists) {
              otpData.userexists = true;
              if (response.data.message === "User Already Register as admin") {
                formik.setErrors({
                  ...formik.errors,
                  email: response.data.message,
                });
                setLoading(false);
                return false;
              } else {
                if (getSessionKey("authUser") === null) {
                  const otpPayload = {
                    email: formik.values.email,
                    sendby: "email",
                    type: otpData.userexists ? "login" : "verification",
                  };
                  axios
                    .post(
                      `${process.env.REACT_APP_API_URL}auth/otp`,
                      otpPayload
                    )
                    .then((otpRes) => {
                      if (otpRes.data.success) {
                        setLoading(false);
                        navigate("/otp", { state: otpData });
                      }
                    })
                    .catch((error) => {
                      formik.setErrors({
                        ...formik.errors,
                        email:
                          error.response.data.error ===
                          "Account deletion request in progress"
                            ? t("login_UserDelReqError")
                            : error.response.data.error,
                      });
                      setLoading(false);
                      if (
                        error.response.status === 403 ||
                        error.response.status === 401
                      ) {
                        navigate("/enter-pin");
                      } else {
                        console.error("Error fetching data:", error.message);
                      }
                    });
                } else {
                  callRegisterFlow();
                }
              }
            } else {
              if (getSessionKey("authUser") === null) {
                otpData.userexists = false;
                const otpPayload = {
                  email: formik.values.email,
                  sendby: "email",
                  type: otpData.userexists ? "login" : "verification",
                };
                axios
                  .post(`${process.env.REACT_APP_API_URL}auth/otp`, otpPayload)
                  .then((otpRes) => {
                    if (otpRes.data.success) {
                      setLoading(false);
                      navigate("/otp", { state: otpData });
                    }
                  })
                  .catch((error) => {
                    formik.setErrors({
                      ...formik.errors,
                      email: error.response.data.error,
                    });
                    setLoading(false);
                    if (
                      error.response.status === 403 ||
                      error.response.status === 401
                    ) {
                      navigate("/enter-pin");
                    } else {
                      console.error("Error fetching data:", error.message);
                    }
                  });
              } else {
                callRegisterFlow();
              }
            }
          }
        })
        .catch((error) => {
          setLoading(false);
          if (error.response.status === 403 || error.response.status === 401) {
            navigate("/enter-pin");
          } else {
            console.error("Error fetching data:", error.message);
          }
        });
    },
  });

  const callRegisterFlow = () => {
    const userRegPayload = {
      email: formik.values.email,
      role: "basic",
      type: "user",
      lang_id: getSessionKey("selectedLang"),
    };
    axios
      .post(`${process.env.REACT_APP_API_URL}auth/register`, userRegPayload, {
        headers: {
          "skip-otp": true,
        },
      })
      .then((response) => {
        setSessionKey("authToken", response.data.data);
        setSessionKey("userRegStatus", "");
        if (response.data.success) {
          setLoading(false);
          navigate("/signup");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error.message);
        setLoading(false);
      });
  };

  const handleSkipandExplore = () => {
    setSessionKey("userRole", "explorer");
    navigate("/impact");
  };

  return (
    <>
      {loading ? <Loader /> : null}
      <header>
        <Header backLink={true}></Header>
      </header>
      <main>
        <div className="lyt-main typ-login">
          <div className={`${styles.section}`}>
            <div className={`${styles.sectionHead}`}>
              <h1 className={`${styles.pgTitle}`}>{t("login_pgTitle")}</h1>
              <p className={`${styles.subTitle}`}>{t("login_pgSubTitle")}</p>
            </div>
            <div className="bs-form">
              <form onSubmit={formik.handleSubmit}>
                <div className="form-body">
                  <div className="form-group">
                    <FormControl variant="standard">
                      <TextField
                        id="email"
                        placeholder="abc@xyz.com"
                        label="Email"
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.email && Boolean(formik.errors.email)
                        }
                        helperText={formik.touched.email && formik.errors.email}
                      />
                    </FormControl>
                  </div>
                </div>
                <div className="form-foot">
                  <Button
                    btnStyle="primary"
                    type="submit"
                    disabled={!formik.isValid}
                  >
                    {t("login_otpBtn")}
                  </Button>
                </div>
              </form>
            </div>
            {getSessionKey("authUser") === null ? (
              <div className={`${styles.ctaWrap}`}>
                <Button btnStyle="text" onClick={handleSkipandExplore}>
                  {t("login_skipBtn")}
                </Button>
              </div>
            ) : null}
          </div>
        </div>
      </main>
      <BottomSheet
        title={t("login_drawerTitle")}
        primaryBtnText={t("login_drawerBtn")}
        size="medium"
        open={openNoteDrawer && getSessionKey("authUser") === null}
        setOpen={setOpenNoteDrawer}
        btnDisabled={!isCheckboxChecked}
        btnOnClick={handleButtonClick}
        className="disable-close"
        disableOverlayClose={true}
      >
        <div className={`${styles.contentWrap}`}>
          <p className={`${styles.desc}`}>{t("login_drawerMsg")}</p>
        </div>
        <div className="check-foot">
          <CheckBox
            id="legalNote"
            label={t("login_drawerAcceptCheck")}
            name="Legal Note"
            onChange={handleCheckboxChange}
            isChecked={isCheckboxChecked}
          ></CheckBox>
        </div>
      </BottomSheet>
    </>
    // <SuccessError></SuccessError>
  );
}

export default Login;
