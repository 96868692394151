import Header from "src/components/common/header/Header";
import Button from "../../components/common/button/Button";
import BottomSheet from "src/components/bottom-sheet/BottomSheet";
import styles from "./style.module.scss";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import axios from "axios";
import {
  getSessionKey,
  removeSessionKey,
  setSessionKey,
} from "src/helper/sessionServices";

function AdvisorClientPage() {
  const { i18n, t } = useTranslation(["Advisor"]);
  const lang = getSessionKey("selectedLangCode");
  const [relationData, setRelationData] = useState<any>([]);
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/add-client");
  };
  const handleNavigate = () => {
    const urlToShare = window.location.href;
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ action: "share", url: urlToShare })
      );
    }
  };
  const [onboard, setOnboard] = useState({
    id: "c002",
    title: `${t("advisor_registerClientLRTitle")}`,
    description: `${t("advisor_registerClientLRSubTitle")}`,
    isDisabled: false,
  });
  const [bsBtnState, setBSbtnState] = useState(true);
  const [openOnboardDrawer, setOpenOnboardDrawer] = useState(false);
  const [openDeleteDrawer, setOpenDeleteDrawer] = useState(false);
  const [selectedClient, setSelectedClient] = useState<any | null>(null);
  const [selectedRadio, setSelectedRadio] = useState("");
  const [triggerGetData, setTriggerGetData] = useState(true);

  const handleRadio = (e: any, item: any) => {
    setSelectedRadio(item.id);
    setBSbtnState(false);
  };
  const handleOpenOnboardDrawer = () => {
    setOpenOnboardDrawer(true);
    setSelectedRadio("");
    setBSbtnState(true);
  };

  const handleTrashIconClick = (client: Client) => {
    setSelectedClient(client);
    setOpenDeleteDrawer(true);
  };

  const handleDeleteClient = () => {
    // console.log("Deleting client:", selectedClient);
    const headers = {
      headers: {
        Authorization: `Bearer ${
          getSessionKey("authToken") !== null
            ? getSessionKey("authToken").accesstoken
            : ""
        }`,
        "Content-Type": "application/json",
      },
    };
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}relation?id=${selectedClient?.id}&investorId=${selectedClient?.investor?.id}&advisorId=${selectedClient?.advisor?.id}`,
        headers
      )
      .then((res) => {
        if (res.data.success) {
          setSelectedClient(null);
          removeSessionKey("selectedClient");
          setOpenDeleteDrawer(false);
          setTriggerGetData(true);
        }
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          navigate("/enter-pin");
        } else {
          console.error("Error fetching data:", error.message);
        }
      });
  };

  const nameInitials = (firstName: string, lastName: string) => {
    return firstName?.charAt(0) + lastName?.charAt(0);
  };
  interface Client {
    id: number;
    firstName: string;
    lastName: string;
    profileImage: string;
    profileImageURL: string;
    email: string;
  }
  const clientData = [
    {
      id: 1,
      firstName: "Rinaldo",
      lastName: "Piaggio",
      profileImage: `/assets/icons/ferruccio.svg`,
      profileImageURL: "",
      email: "rinaldo.piaggio@hotmail.com",
    },
    {
      id: 2,
      firstName: "Vincenza",
      lastName: "Milano",
      profileImage: `/assets/icons/ferruccio.svg`,
      profileImageURL: "",
      email: "veeencenzamilanooo@hotmail.com",
    },
  ];
  const onboardList = [
    {
      id: "c001",
      title: `${t("advisor_registerClientInvestorTitle")}`,
      icon: "profile",
      description: `${t("advisor_registerClientInvestorSubTitle")}`,
      isDisabled: false,
    },
    {
      id: "c002",
      title: `${t("advisor_registerClientLRTitle")}`,
      icon: "advisor",
      description: `${t("advisor_registerClientLRSubTitle")}`,
      isDisabled: false,
    },
  ];
  useEffect(() => {
    i18next.changeLanguage(lang);
  }, []);

  useEffect(() => {
    if (triggerGetData) {
      axios
        .get(`${process.env.REACT_APP_API_URL}relation`, {
          headers: {
            Authorization: `Bearer ${
              getSessionKey("authToken") !== null
                ? getSessionKey("authToken").accesstoken
                : ""
            }`,
          },
        })
        .then((response) => {
          setRelationData(response?.data?.data);
          setSessionKey("relationData", response?.data?.data);
        })
        .catch((error) => {
          if (error.response.status === 403 || error.response.status === 401) {
            navigate("/enter-pin");
          } else {
            console.error("Error fetching data:", error.message);
          }
        });

      setTriggerGetData(false);
    }
  }, [triggerGetData]);

  return (
    <>
      <header>
        <Header backLink={true}></Header>
      </header>
      <main>
        <div className="lyt-main typ-btn-spacing">
          <section>
            <div className="bs-section typ-lg-top">
              <div className="sec-head">
                <h1 className={`cm-sec-title typ-big ${styles.title}`}>
                  {t("advisor_clientTitle")}
                </h1>
                <p className={`${styles.subTitle}`}>
                  {t("advisor_clientSubTitle")}
                </p>
              </div>
              <div className="sec-cont">
                <div className={`${styles.subWrap}`}>
                  <ul className={`${styles.subList}`}>
                    {relationData.map((item: any, index: number) =>
                      item?.requestStatus === "approved" ? (
                        <li className={`${styles.subItem}`} key={index}>
                          <div className={`${styles.logoWrap}`}>
                            {item?.investor?.userdetail?.img ? (
                              <img
                                src={item?.investor?.userdetail?.img}
                                alt={`profile img`}
                              />
                            ) : (
                              <>
                                <span>
                                  {nameInitials(
                                    item?.investor?.role === "legalrepresent"
                                      ? item?.investor?.userLrdetail[0]
                                          ?.companyname
                                      : item?.investor?.userdetail?.firstname,
                                    item?.investor?.role === "legalrepresent"
                                      ? ""
                                      : item?.investor?.userdetail?.lastname
                                  )}
                                </span>
                              </>
                            )}
                          </div>
                          <div className={`${styles.titleWrap}`}>
                            <strong className={`${styles.clientName}`}>
                              {item?.investor?.role === "legalrepresent"
                                ? `${item?.investor?.userLrdetail[0]?.companyname}`
                                : `${
                                    item?.investor?.userdetail?.firstname
                                  }${" "}${
                                    item?.investor?.userdetail?.lastname
                                  }`}
                            </strong>
                            <p className={`${styles.clientMail}`}>
                              {item?.investor?.email}
                            </p>
                          </div>
                          <button
                            type="button"
                            onClick={() => handleTrashIconClick(item)}
                          >
                            <i className={`${styles.trash}`}></i>
                          </button>
                        </li>
                      ) : null
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className={`${styles.footWrap}`}>
          <Button btnStyle="secondary" onClick={handleOpenOnboardDrawer}>
            {t("advisor_onboardBtn")}
          </Button>
          <Button btnStyle="primary" onClick={handleClick}>
            {t("advisor_addClientTitle")}
          </Button>
        </div>
      </main>
      <BottomSheet
        title={t("advisor_onboardBtn")}
        subText={t("advisor_onboardSubTitle")}
        primaryBtnText={t("advisor_confirmBtn")}
        size="medium"
        open={openOnboardDrawer}
        setOpen={setOpenOnboardDrawer}
        btnDisabled={bsBtnState}
        btnOnClick={handleNavigate}
      >
        <div className={`${styles.cardWrap}`}>
          <ul className={`${styles.onboardList}`}>
            {onboardList.map((item, index) => {
              return (
                <li key={index} className={`${styles.onboardItem}`}>
                  <div className={`${styles.journeyCard}`}>
                    <input
                      onChange={(e) => handleRadio(e, item)}
                      type="radio"
                      name="onboard-list"
                      id={item.id}
                      checked={selectedRadio === item.id}
                      disabled={item.isDisabled}
                    />
                    <label htmlFor={item.id}>
                      <span
                        className={`${styles.userIcon} ${styles[item.icon]}`}
                      ></span>
                      <div className={`${styles.titleDescWrap}`}>
                        <strong>{item.title}</strong>
                        <p>{item.description}</p>
                      </div>
                    </label>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </BottomSheet>
      {selectedClient && openDeleteDrawer && (
        <BottomSheet
          title={t("advisor_removeDrawerTitle")}
          subText={t("advisor_removeDrawerSubTitle")}
          primaryBtnText={t("advisor_removeBtn")}
          size="medium"
          open={openDeleteDrawer}
          setOpen={setOpenDeleteDrawer}
          btnOnClick={handleDeleteClient}
        >
          <div className={`${styles.cardWrap}`}>
            <div className={`${styles.clientProfile}`}>
              <div className={`${styles.logoWrap}`}>
                {selectedClient?.investor?.userdetail?.img ? (
                  <img
                    src={selectedClient?.investor?.userdetail?.img}
                    alt={`profile img`}
                  />
                ) : (
                  <>
                    <span>
                      {nameInitials(
                        selectedClient?.investor?.role === "legalrepresent"
                          ? selectedClient?.investor?.userLrdetail[0]
                              ?.companyname
                          : selectedClient?.investor?.userdetail?.firstname,
                        selectedClient?.investor?.role === "legalrepresent"
                          ? ""
                          : selectedClient?.investor?.userdetail?.lastname
                      )}
                    </span>
                  </>
                )}
              </div>
              <div className={`${styles.titleWrap}`}>
                <strong className={`${styles.clientName}`}>
                  {selectedClient?.investor?.role === "legalrepresent"
                    ? `${selectedClient?.investor?.userLrdetail[0]?.companyname}`
                    : `${
                        selectedClient?.investor?.userdetail?.firstname
                      }${" "}${selectedClient?.investor?.userdetail?.lastname}`}
                </strong>
                <p className={`${styles.clientMail}`}>
                  {selectedClient?.investor?.email}
                </p>
              </div>
            </div>
          </div>
        </BottomSheet>
      )}
    </>
  );
}

export default AdvisorClientPage;
