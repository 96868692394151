import React, { useState } from "react";
import styles from "./style.module.scss";
import Button from "src/components/common/button/Button";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { getSessionKey } from "src/helper/sessionServices";
import { useNavigate } from "react-router-dom";
import Header from "../common/header/Header";
import { setSessionKey } from "src/helper/sessionServices";
export default function AccordionModule() {
  const navigate = useNavigate();
  const userData = getSessionKey("userData");
  const handleEmailVerification = () => {
    navigate("/accordion-module/update-email");
    setSessionKey("settingType", "Email");
  };
  const handlePhoneVerification = () => {
    navigate("/accordion-module/update-profile");
    setSessionKey("settingType", "Phone");
  };
  return (
    <>
      <header>
        <Header backLink={true}></Header>
      </header>
      <main>
        <div className="lyt-main">
          <section>
            <div className="bs-section">
              <div className="sec-head">
                <h1 className={`cm-sec-title typ-big ${styles.title}`}>
                  Personal Details
                </h1>
              </div>
              <div className="sec-cont">
                <div className="bs-confirmation">
                  <div className="confirm-card">
                    <div className="head-wrap">
                      <strong className="card-title">Personal Details</strong>
                    </div>
                    <div className="cont-wrap">
                      <ul className="label-value-list">
                        <li className="label-value-item">
                          <label>Email</label>
                          <span className="value small">{`${userData.email}`}</span>
                          <Button
                            btnStyle="text"
                            onClick={handleEmailVerification}
                          >
                            Edit
                          </Button>
                        </li>
                        <li className="label-value-item">
                          <label>Phone Number</label>
                          <span className="value">{`${userData.contact}`}</span>
                          <Button
                            btnStyle="text"
                            onClick={handlePhoneVerification}
                          >
                            Edit
                          </Button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    </>
  );
}
