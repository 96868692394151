import { useEffect, useState } from "react";
import styles from "./style.module.scss";
import OpportunitiesCard, {
  OpportunitiesCardData,
} from "src/components/opportunities-card/OpportunitiesCard";
import { getSessionKey } from "src/helper/sessionServices";
import Exploration from "src/components/exploration/exploration";
import Loader from "src/components/common/loader/Loader";
import { useLoader } from "src/context/LoaderContext";
import axios from "axios";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useNavigate } from "react-router-dom";

function OpportunitiesPage() {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation(["Opportunities"]);
  const lang = getSessionKey("selectedLangCode");
  const { loading, setLoading } = useLoader();
  const [opportunitiesCards, setOpportunitiesCards] = useState<
    OpportunitiesCardData[]
  >([]);

  useEffect(() => {
    i18next.changeLanguage(lang);
  }, []);
  useEffect(() => {
    // Get company listing
    if (
      getSessionKey("userData")?.access_group === "advanced" ||
      getSessionKey("userData")?.access_group === "intermediate"
    ) {
      setLoading(true);
      axios
        .get(
          `${process.env.REACT_APP_API_URL}company?lang_id=${getSessionKey(
            "selectedLang"
          )}`,
          {
            headers: {
              Authorization: `Bearer ${
                getSessionKey("authToken") !== null
                  ? getSessionKey("authToken").accesstoken
                  : ""
              }`,
            },
          }
        )
        .then((response) => {
          console.log(response, "Opp card!!!");
          setLoading(false);
          setOpportunitiesCards(response?.data?.data);
        })
        .catch((error) => {
          setLoading(false);
          if (error.response.status === 403 || error.response.status === 401) {
            navigate("/enter-pin");
          } else {
            console.error("Error fetching data:", error.message);
          }
        });
    }
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          {getSessionKey("userRole") === "explorer" &&
          getSessionKey("authToken") === null ? (
            <div className="blank-wrap">
              <Exploration
                buttonText={t("opportunities_registerTitle")}
                navigationLink="/login"
                logoURL="/assets/logos/explore-opportunities.png"
                description={t("opportunities_registerSubTitle")}
              />
            </div>
          ) : (
            <>
              <div className="lyt-main typ-main">
                <section>
                  <div className="bs-section typ-depth">
                    <div className="sec-head">
                      <h2 className="cm-sec-title">
                        {t("opportunities_pgTitle")}
                      </h2>
                    </div>
                    <div className="sec-cont">
                      <div className={`${styles.opportunitiesList}`}>
                        {opportunitiesCards.map((item, index) => {
                          return (
                            <div key={index} className={`${styles.item}`}>
                              <OpportunitiesCard {...item}></OpportunitiesCard>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}

export default OpportunitiesPage;
