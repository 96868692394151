import { useState, useEffect } from "react";
import styles from "./style.module.scss";
import Header from "src/components/common/header/Header";
import Button from "src/components/common/button/Button";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { headers } from "src/helper/authToken";
import { HighlightCardData } from "src/components/highlight-card/HighlightCard";
import { convertTime } from "src/helper/convertTime";
import { optionCountry } from "src/helper/optionCountry";
import { formatDate } from "src/helper/formatDate";
import { getSessionKey } from "src/helper/sessionServices";
import { useLoader } from "src/context/LoaderContext";
import Loader from "src/components/common/loader/Loader";
import { useTranslation } from "react-i18next";
function HighlightDetailData() {
  const { loading, setLoading } = useLoader();
  const [highlightDetailData, setHighlightDetail] =
    useState<HighlightCardData>();
  const [highlightDetailFav, setHighlightDetailFav] =
    useState<HighlightCardData>();
  const [highlightDetailInterest, setHighlightDetailInterest] =
    useState<HighlightCardData>();
  const [isFavorite, setIsFavorite] = useState(false);
  const userData = getSessionKey("authToken");
  console.log(getSessionKey("userData"), "Userdata!!!");
  const { i18n, t } = useTranslation(["Highlight"]);
  const lang = getSessionKey("selectedLangCode");

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const highlightId = searchParams.get("highlightcode");
  const getLabelByValue = (value: string) => {
    const item = optionCountry.find((item) => item.label === value);
    return item ? item.label : "Label not found";
  };

  const [favFlagToReload, setFavFlagToReload] = useState<boolean>(true);
  useEffect(() => {
    if (favFlagToReload) {
      setLoading(true);
      // Make a GET request with the specified headers
      axios
        .get(
          `${
            process.env.REACT_APP_API_URL
          }auth/highlight?highlightcode=${highlightId}&lang_id=${
            getSessionKey("userData")?.lang_id
          }`,
          {
            headers: {
              Authorization: `Bearer ${
                getSessionKey("authToken") !== null
                  ? getSessionKey("authToken").accesstoken
                  : ""
              }`,
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          setHighlightDetail(response?.data?.data[0]);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          if (error.response.status === 403 || error.response.status === 401) {
            navigate("/enter-pin");
          } else {
            console.error("Error fetching data:", error.message);
          }
        });
      setFavFlagToReload(false);
    }
  }, [highlightId, favFlagToReload]);

  const [favouriteId, setFavouriteId] = useState<number | null>(null);
  const [interestedId, setInterestedId] = useState<number | null>(null);
  useEffect(() => {
    let filteredArray: any = highlightDetailData?.HighlightInterestNFavourites
      ? highlightDetailData?.HighlightInterestNFavourites.filter(
          (item) => item.type === "favourite"
        )
      : "";
    let filteredArrayInterested: any =
      highlightDetailData?.HighlightInterestNFavourites
        ? highlightDetailData?.HighlightInterestNFavourites.filter(
            (item) => item.type === "interested"
          )
        : "";
    if (filteredArray.length > 0) {
      setIsFavorite(true);
      setFavouriteId(Number(filteredArray[0].id));
    } else {
      setIsFavorite(false);
      setFavouriteId(null);
    }
    if (filteredArrayInterested.length > 0) {
      setInterestedId(Number(filteredArrayInterested[0].id));
    } else {
      setInterestedId(null);
    }
  }, [highlightDetailData]);

  const itineraryHighlights =
    highlightDetailData?.highligthdetail &&
    highlightDetailData?.highligthdetail.length > 0
      ? highlightDetailData?.highligthdetail.filter(
          (highlight) => highlight.type === "itinerary"
        )
      : [];
  const stepsHighlights =
    highlightDetailData?.highligthdetail &&
    highlightDetailData?.highligthdetail.length > 0
      ? highlightDetailData?.highligthdetail.filter(
          (highlight) => highlight.type === "steps"
        )
      : [];

  const navigate = useNavigate();
  const handleClick = () => {
    setLoading(true);
    const data = {
      highlightcode: highlightId,
      userid: userData?.id,
      type: "interested",
      redirectionlink:
        highlightDetailData?.eventType === "online"
          ? highlightDetailData?.meeturl
          : "",
      email: userData?.email,
    };
    axios
      .post(`${process.env.REACT_APP_API_URL}interestnfovourite`, data, {
        headers: {
          Authorization: `Bearer ${
            getSessionKey("authToken") !== null
              ? getSessionKey("authToken").accesstoken
              : ""
          }`,
        },
      })
      .then((response) => {
        //setHighlightDetail(response?.data?.data[0]);
        if (response?.data?.status === "Success") {
          setLoading(false);
          let historyData = {
            reference: "highlight interested",
          };
          navigate("/acknowledgement", { state: historyData });
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 403 || error.response.status === 401) {
          navigate("/enter-pin");
        } else {
          console.error("Error fetching data:", error.message);
        }
      });
  };

  const getDownloadObj = {
    highlightid: highlightId,
    userid: userData?.id,
    type: "favourite",
    redirectionlink:
      highlightDetailData?.eventType === "online"
        ? highlightDetailData?.meeturl
        : "",
    email: userData?.email,
  };
  const filteredArray = highlightDetailData?.HighlightInterestNFavourites
    ? highlightDetailData?.HighlightInterestNFavourites.filter(
        (item) => item.type === "interested"
      )
    : "";
  const filteredArrayFavourite =
    highlightDetailData?.HighlightInterestNFavourites
      ? highlightDetailData?.HighlightInterestNFavourites.filter(
          (item) => item.type === "favourite"
        )
      : "";

  const handleWithdrawInterested = () => {
    setLoading(true);
    axios
      .delete(
        `${process.env.REACT_APP_API_URL}interestnfovourite?id=${interestedId}`,
        {
          headers: {
            Authorization: `Bearer ${
              getSessionKey("authToken") !== null
                ? getSessionKey("authToken").accesstoken
                : ""
            }`,
          },
        }
      )
      .then((response: any) => {
        if (response?.data?.status === "Success") {
          setFavFlagToReload(true);
        }
      })
      .catch((error: any) => {
        setLoading(false);
        if (error.response.status === 403 || error.response.status === 401) {
          navigate("/enter-pin");
        } else {
          console.error("Error fetching data:", error.message);
        }
      });
  };

  const handleFavorite = () => {
    setLoading(true);
    if (isFavorite) {
      axios
        .delete(
          `${process.env.REACT_APP_API_URL}interestnfovourite?id=${favouriteId}`,
          {
            headers: {
              Authorization: `Bearer ${
                getSessionKey("authToken") !== null
                  ? getSessionKey("authToken").accesstoken
                  : ""
              }`,
            },
          }
        )
        .then((response: any) => {
          if (response?.data?.status === "Success") {
            setFavFlagToReload(true);
          }
        })
        .catch((error: any) => {
          setLoading(false);
          if (error.response.status === 403 || error.response.status === 401) {
            navigate("/enter-pin");
          } else {
            console.error("Error fetching data:", error.message);
          }
        });
    } else {
      const payload = {
        highlightcode: highlightId,
        userid: getSessionKey("authToken")?.id,
        type: "favourite",
      };
      axios
        .post(`${process.env.REACT_APP_API_URL}interestnfovourite`, payload, {
          headers: {
            Authorization: `Bearer ${
              getSessionKey("authToken") !== null
                ? getSessionKey("authToken").accesstoken
                : ""
            }`,
          },
        })
        .then((response: any) => {
          if (response?.data?.status === "Success") {
            setFavFlagToReload(true);
          }
        })
        .catch((error: any) => {
          setLoading(false);
          if (error.response.status === 403 || error.response.status === 401) {
            navigate("/enter-pin");
          } else {
            console.error("Error fetching data:", error.message);
          }
        });
    }
    // setIsFavorite(!isFavorite);
  };

  return (
    <>
      {loading ? <Loader /> : <></>}
      <header>
        <Header backLink={true}></Header>
        {getSessionKey("authToken") && (
          <button className="fav-btn" onClick={handleFavorite}>
            {isFavorite ? (
              <i className="favorite-filled"></i>
            ) : (
              <i className="favorite"></i>
            )}
          </button>
        )}
      </header>
      <main>
        <div className="lyt-main typ-highlight-detail">
          <section>
            <div className="bs-section">
              <div className="sec-cont">
                <div className={`${styles.highlightDetailWrap}`}>
                  <div className={`${styles.imgWrap}`}>
                    <img src={`${highlightDetailData?.imageUrl}`} alt="" />
                  </div>
                  <div className={`${styles.contentWrap}`}>
                    <div className={`${styles.contHead}`}>
                      <h1 className={`${styles.eventTitle}`}>
                        {highlightDetailData?.name}
                      </h1>
                      <span className={`${styles.date}`}>
                        {highlightDetailData?.eventType === "offline" ? (
                          <i className={`${styles.location}`}></i>
                        ) : highlightDetailData?.eventType ===
                          "communication" ? (
                          <i className={`${highlightDetailData?.subtype}`}></i>
                        ) : (
                          <i
                            className={`${highlightDetailData?.eventType}`}
                          ></i>
                        )}
                        {highlightDetailData?.eventType === "offline"
                          ? highlightDetailData?.provience !== "" &&
                            highlightDetailData?.provience !== null
                            ? highlightDetailData?.provience + ", "
                            : "" + getLabelByValue(highlightDetailData?.country)
                          : highlightDetailData?.eventType === "communication"
                          ? highlightDetailData?.subtype
                          : highlightDetailData?.eventType}
                      </span>
                      <span className={`${styles.date}`}>
                        <i className={`${styles.calendar}`}></i>
                        {formatDate(highlightDetailData?.eventdate!)}
                      </span>
                      {highlightDetailData?.eventType !== "communication" ? (
                        <span className={`${styles.date}`}>
                          <i className={`${styles.time}`}></i>
                          {convertTime(
                            highlightDetailData?.eventtime || "",
                            highlightDetailData?.eventendtime !== "" &&
                              highlightDetailData?.eventendtime
                              ? highlightDetailData?.eventendtime
                              : highlightDetailData?.eventtime || ""
                          )}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className={`${styles.section}`}>
                      <h3 className={`${styles.secTitle}`}>
                        {t("highlight_detailsAbout")}
                      </h3>
                      <div
                        className="cont-body"
                        dangerouslySetInnerHTML={{
                          __html: highlightDetailData?.about!,
                        }}
                      ></div>
                    </div>
                    {highlightDetailData?.eventType !== "communication" &&
                    itineraryHighlights.length > 0 ? (
                      <div className={`${styles.section}`}>
                        <h2 className={`${styles.secTitle}`}>
                          {t("highlight_detailsItinerary")}
                        </h2>
                        <div className={`${styles.cardWrap}`}>
                          <div className={`${styles.card}`}>
                            <div className={`${styles.stepsList}`}>
                              {itineraryHighlights.map((highlight, index) => (
                                <div className={`${styles.steps}`} key={index}>
                                  <p>{highlight.title}</p>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {highlightDetailData?.eventType !== "communication" ? (
                      <div className={`${styles.section}`}>
                        <h2 className={`${styles.secTitle}`}>
                          {t("highlight_detailsEnsure")}
                        </h2>
                        <div className={`${styles.cardWrap}`}>
                          <div className={`${styles.card}`}>
                            <div className={`${styles.checkList}`}>
                              {stepsHighlights.map((highlight, index) => (
                                <div
                                  className={`${styles.iconTextWrap}`}
                                  key={index}
                                >
                                  <i className={`${styles.check}`}></i>
                                  <span className={`${styles.date}`}>
                                    {highlight.title}
                                  </span>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {highlightDetailData?.eventType === "offline" ? (
                      <div className={`${styles.section}`}>
                        <h2 className={`${styles.secTitle}`}>
                          {t("highlight_detailsVenue")}
                        </h2>

                        <div
                          className={`${styles.contBody} cont-body`}
                          dangerouslySetInnerHTML={{
                            __html: highlightDetailData?.venue!,
                          }}
                        ></div>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className={`${styles.section}`}>
                      <h2 className={`${styles.secTitle}`}>
                        {t("highlight_detailsQuery")}
                      </h2>
                      <div className={`${styles.content}`}>
                        {highlightDetailData?.querydetail}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {highlightDetailData?.eventType !== "communication" ? (
            <>
              {!(filteredArray.length > 0) && getSessionKey("authToken") ? (
                <div className="act-foot">
                  <Button btnStyle="primary" onClick={handleClick}>
                    {t("highlight_interestedBtn")}
                  </Button>
                </div>
              ) : (
                <div className="act-foot">
                  <Button btnStyle="primary" onClick={handleWithdrawInterested}>
                    {t("highlight_withdrawBtn")}
                  </Button>
                </div>
              )}
            </>
          ) : null}
        </div>
      </main>
    </>
  );
}

export default HighlightDetailData;
