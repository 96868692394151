import OtpInput from "react-otp-input";
import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
import Button from "src/components/common/button/Button";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "src/components/common/header/Header";
import axios from "axios";
import { getSessionKey, logoutSession } from "src/helper/sessionServices";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { setSessionKey, clearSession } from "src/helper/sessionServices";
import { useLoader } from "src/context/LoaderContext";
import Loader from "src/components/common/loader/Loader";

function EnterPin() {
  const { loading, setLoading, setUserData } = useLoader();
  const { i18n, t } = useTranslation(["ProfileSettings"]);
  const navigate = useNavigate();
  const [btnValid, setBtnValid] = useState(false);
  const [showError, setShowError] = useState(false);
  const [otp, setOtp] = useState("");
  const userData = getSessionKey("userData");
  const refreshToken = getSessionKey("authToken")?.refreshtoken;

  useEffect(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    setBtnValid(otp.length === 4);
  }, [otp]);

  const handleClick = () => {
    setShowError(false);
    const payload = {
      pin: Number(otp),
      id: userData.id, // current user id
      deviceId: getSessionKey("deviceId"),
    };
    axios
      .post(`${process.env.REACT_APP_API_URL}pin`, payload, {
        headers: {
          Authorization: `Bearer ${getSessionKey("authToken").accesstoken}`,
        },
      })
      .then((res) => {
        if (res.data.success) {
          setLoading(false);
          setSessionKey("authToken", res.data.data);
          navigate("/impact");
        } else {
          setLoading(false);
          setShowError(true);
        }
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          setUserData(null);
          const payload = {
            token: refreshToken,
          };
          axios
            .post(`${process.env.REACT_APP_API_URL}auth/refreshtoken`, payload)
            .then((res) => {
              setLoading(false);
              if (res.data.success) {
                setSessionKey("authToken", res.data.data);
                navigate("/impact");
              } else {
                setUserData(null);
                logoutSession(navigate);
              }
            })
            .catch((err) => {
              console.error("refresh token error");
              setUserData(null);
              logoutSession(navigate);
            });
        } else {
          setLoading(false);
          console.error("Error refresh token fetching data:", error.message);
          setShowError(true);
        }
      });
  };

  const handleForgetPin = () => {
    let otpData = {
      type: "email",
      iconName: "email",
      value: getSessionKey("authToken")?.email,
      source: "enter-pin",
      userexists: true,
    };
    const otpPayload = {
      email: getSessionKey("authToken")?.email,
      sendby: "email",
      type: "forgot-pin",
    };
    axios
      .post(`${process.env.REACT_APP_API_URL}auth/otp`, otpPayload)
      .then((otpRes) => {
        if (otpRes.data.success) {
          setLoading(false);
          navigate("/otp", { state: otpData });
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error.message);
      });
  };

  return (
    <>
      {loading ? <Loader /> : <></>}
      <header>
        <Header></Header>
      </header>

      <div className="lyt-main">
        <div className={`${styles.section}`}>
          <div className={`${styles.sectionHead}`}>
            <div className={`${styles.profileWrap}`}>
              <img
                src={
                  userData?.userdetail?.img === "" ||
                  userData?.userdetail?.img === null
                    ? "https://ucarecdn.com/1ccabd74-6863-4125-808a-b9d1315e272e/user.png"
                    : userData?.userdetail?.img
                }
                alt="Client Logo"
              />
            </div>
            <h2 className={`${styles.profileTitle}`}>
              {t("enterPin_introText")},{" "}
              {`${
                userData === null
                  ? ""
                  : getSessionKey("userRole") === "legalrepresent"
                  ? userData.userdetail.userlrdetail.filter(
                      (user: any) => user.detailtype === "company"
                    )[0].companyname
                  : userData?.userdetail?.firstname
              }`}
            </h2>
          </div>
          <div className="bs-form typ-pin">
            <p className={`${styles.formTitle}`}>{t("enterPin_loginText")}</p>
            <form>
              <div className="form-body">
                <div className="form-group">
                  <div className={`${showError ? "invalid" : ""} otp-wrap`}>
                    <OtpInput
                      // id="otpPin"
                      // name="otpPin"
                      value={otp}
                      onChange={setOtp}
                      numInputs={4}
                      inputType="number"
                      renderInput={(props) => <input {...props} required />}
                    />
                    {showError && (
                      <span className="MuiFormHelperText-root Mui-error">
                        {t("profilesettings_incorrectPin")}
                      </span>
                    )}
                  </div>
                  <div className="forgot-pin">
                    <button type="button" onClick={handleForgetPin}>
                      {t("profilesettings_forgotPin")}
                    </button>
                  </div>
                </div>
              </div>
              <div className="form-foot">
                <Button
                  btnStyle="primary"
                  type="button"
                  onClick={handleClick}
                  disabled={!btnValid}
                >
                  {t("profilesettings_confirmBtn")}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default EnterPin;
