import { ReactNode, useEffect, useState } from "react";
import styles from "./style.module.scss";
import BottomSheet from "src/components/bottom-sheet/BottomSheet";
import axios from "axios";
import { authToken } from "src/helper/authToken";
import { DocumentDataType } from "src/pages/signup/common/identity-proof/IdentityProof";
import { getSessionKey } from "src/helper/sessionServices";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useNavigate } from "react-router-dom";

export interface fileDataValidation {
  id?: string;
  value?: number | string | null;
  getFileData?: (
    file: any,
    fileData: DocumentDataType | null,
    index?: number
  ) => void;
  error?: boolean;
  helperText?: ReactNode;
  preUploadedFile?: any;
  preFileObj?: any;
  editState?: boolean;
  prefillUploadedFileData?: any;
  prefillFileObjData?: any;
  previewFileURL?: string;
  index?: number;
}

function DocumentUpload(props: fileDataValidation) {
  const { i18n, t } = useTranslation(["Header"]);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [deleteFlag, setDeleteFlag] = useState<boolean>(false);
  const [uploadedFile, setUploadedFile] = useState({
    id: "",
    name: "",
    size: "",
    type: "",
  });
  const [openFilePreviewDrawer, setOpenFilePreviewDrawer] = useState(false);
  const [previewURL, setPreviewURL] = useState<string | undefined>("");
  const [previewFileObj, setPreviewFileObj] = useState<Blob>({} as Blob);

  const handleUpload = (e: any) => {
    const file = e.target.files && e.target.files[0];

    if (file) {
      // Check file size
      if (file.size > 5 * 1024 * 1024) {
        setErrorMessage(`${t("header_errorFileSize")}`);
        return;
      }
      // Check file type
      if (!file.type.includes("image") && !file.type.includes("pdf")) {
        setErrorMessage(`${t("header_errorUploadFile")}`);
        return;
      }

      const formData = new FormData();
      formData.append("file", file);
      formData.append(
        "type",
        file.type.includes("pdf") ? "document" : "images"
      );
      axios
        .post(`${process.env.REACT_APP_API_URL}documentUpload`, formData, {
          headers: {
            Authorization: `Bearer ${getSessionKey("authToken").accesstoken}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.data.success) {
            setErrorMessage(null);
            setUploadedFile({
              id: "" + e.target.files[0].lastModified,
              name: e.target.files[0].name,
              size: "" + Math.round(e.target.files[0].size / 1024),
              type: e.target.files[0].type,
            });
            setPreviewFileObj(e.target.files[0]);

            if (props.getFileData)
              props.getFileData(e.target.files[0], res.data.data, props?.index);
          }
        })
        .catch((error) => {
          if (error.response.status === 403 || error.response.status === 401) {
            navigate("/enter-pin");
          } else {
            console.error("Error fetching data:", error.message);
          }
        });
    }
  };

  const filePreview = () => {
    console.log("previewURL", previewURL);
    setOpenFilePreviewDrawer(true);
  };

  useEffect(() => {
    console.log(props, "Props!!!");
    if (props.editState && !deleteFlag) {
      setUploadedFile({
        id: props.preFileObj?.lastModified,
        name: props.preUploadedFile?.filename,
        size: props.preUploadedFile?.filesize,
        type: props.preUploadedFile?.filetype,
      });
      setPreviewFileObj(props.preFileObj);
    }
    setPreviewURL(props.previewFileURL);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  useEffect(() => {
    if (props.prefillUploadedFileData) {
      setUploadedFile(props.prefillUploadedFileData);
    } else if (!props.editState) {
      setUploadedFile({
        id: "",
        name: "",
        size: "",
        type: "",
      });
    }
    if (props.prefillFileObjData) {
      setPreviewFileObj(props.prefillFileObjData);
    } else if (!props.editState) {
      setPreviewFileObj({} as Blob);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.prefillUploadedFileData, props.prefillFileObjData]);

  return (
    <>
      <div className="bs-upload">
        <div className="doc-card">
          {uploadedFile?.id === "" ? (
            <div className="upload-section">
              <div className="upload-item upload-file">
                <span className="text">{t("header_uploadTitle")}</span>
                <input
                  type="file"
                  name=""
                  id={"file" + props.id}
                  accept="image/*,application/pdf"
                  onChange={(e) => handleUpload(e)}
                />
              </div>
            </div>
          ) : (
            <div className="uploaded-state">
              <div className="cm-document-wrap">
                <i
                  className={`document-icon ${
                    uploadedFile?.type.indexOf("image") !== -1
                      ? "image"
                      : "document"
                  }`}
                ></i>
                <div className="info-wrap">
                  <span className="doc-title">{uploadedFile?.name}</span>
                  {Number(uploadedFile?.size) > 0 ? (
                    <span className="foot-note">{uploadedFile?.size} KB</span>
                  ) : null}
                </div>
                <div className="action-wrap">
                  <button
                    type="button"
                    className="show-media"
                    onClick={filePreview}
                  >
                    <i></i>
                  </button>
                  <button
                    type="button"
                    className="trash-btn"
                    onClick={() => {
                      setUploadedFile({
                        id: "",
                        name: "",
                        size: "",
                        type: "",
                      });
                      setDeleteFlag(true);
                      setPreviewURL("");
                      if (props.getFileData)
                        props.getFileData("", null, props?.index);
                    }}
                  >
                    <i></i>
                  </button>
                </div>
              </div>
            </div>
          )}

          <div className="upload-note">
            <strong className="note-title">
              <i></i>
              {t("header_noteTitle")}
            </strong>
            <ul className="note-list">
              <li className="note-item">{t("header_maxFileSize")}</li>
            </ul>
          </div>
        </div>
        {errorMessage && <p className="error-text">{errorMessage}</p>}
      </div>
      <BottomSheet
        open={openFilePreviewDrawer}
        primaryBtnText={t("header_confirmBtn")}
        btnDisabled={false}
        title={uploadedFile?.name}
        size="large"
        setOpen={setOpenFilePreviewDrawer}
        btnOnClick={() => setOpenFilePreviewDrawer(false)}
      >
        <div className="upload-preview">
          {uploadedFile?.type.indexOf("image") !== -1 ? (
            <>
              <img src={previewURL} alt="" />
            </>
          ) : (
            <>
              <iframe width="100%" height="400" src={previewURL} />
            </>
          )}
        </div>
      </BottomSheet>
    </>
  );
}

export default DocumentUpload;
