import Header from "src/components/common/header/Header";
import Button from "src/components/common/button/Button";
import CheckBox from "src/components/common/checkbox/CheckBox";
import { useNavigate } from "react-router-dom";

import styles from "./style.module.scss";
import { useEffect, useState } from "react";
import {
  getSessionKey,
  removeSessionKey,
  setSessionKey,
} from "src/helper/sessionServices";
import axios from "axios";
import { headers } from "../../helper/authToken";
import Loader from "src/components/common/loader/Loader";
import { useLoader } from "src/context/LoaderContext";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

function TermsConditions() {
  const { i18n, t } = useTranslation(["Terms"]);
  const lang = getSessionKey("selectedLangCode");
  const { loading, setLoading } = useLoader();
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);
  const handleChange = (event: any) => {
    event.target.checked ? setIsChecked(true) : setIsChecked(false);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const handleClick = () => {
    setLoading(true);
    console.log("authToken", getSessionKey("authToken"));
    console.log("userRole", getSessionKey("userRole"));
    console.log("langMaster", getSessionKey("langMaster"));
    console.log("selectedLang", getSessionKey("selectedLang"));
    console.log("personalDetails", getSessionKey("personalDetails"));
    console.log("identityProofObj", getSessionKey("identityProofObj"));
    console.log("residenceProofObj", getSessionKey("residenceProofObj"));
    console.log("lrPOIDetails", getSessionKey("lrPOIDetails"));

    const authToken = getSessionKey("authToken");
    const userRole = getSessionKey("userRole");
    const langMaster = getSessionKey("langMaster");
    const selectedLang =
      getSessionKey("authUser") !== null ? "1" : getSessionKey("selectedLang");
    const personalDetails = getSessionKey("personalDetails");
    const identityProofObj = getSessionKey("identityProofObj");
    const residenceProofObj = getSessionKey("residenceProofObj");
    const lrPOIDetails = getSessionKey("lrPOIDetails");
    if (lrPOIDetails) {
      lrPOIDetails["countrycode"] = lrPOIDetails?.countryCode || null;
    }

    let registerLrUserPayload: any = [];
    let registerUserPayload = {};
    if (userRole === "advisor") {
      registerUserPayload = {
        userid: authToken.id,
        userdetailid: authToken.userDetailId | authToken.userdetail.id,
        lang_id: String(selectedLang),
        contact: personalDetails.phn,
        countrycode: personalDetails.countryCode,
        role: userRole,
        //"country": residenceProofObj.country,
        firstname: personalDetails.fname,
        lastname: personalDetails.lname,
        //"residencecountry": residenceProofObj.country,
        //"zipcode": residenceProofObj.zipcode,
        //"city": residenceProofObj.city,
        gender: "",
        dateofbirth: null,
        linkDevice: "",
        img: "https://ucarecdn.com/1ccabd74-6863-4125-808a-b9d1315e272e/user.png",
        //"street": residenceProofObj.streetArea,
        //"housenumber": residenceProofObj.houseNo,
        state: "",
        document: [
          {
            userid: authToken.id,
            userdetailid: authToken.userDetailId | authToken.userdetail.id,
            documenturl: identityProofObj.documentFileURL,
            documenttype: "Identity Proof",
            documentsubtype: identityProofObj.identityProofType,
            expirydate: String(new Date(identityProofObj.documentExpiryDate)),
            documentnumber: identityProofObj.documentNo,
            fileType: identityProofObj.documentFileType,
            fileName: identityProofObj.documentFileName,
            provience: "",
          },
        ],
      };
    } else if (userRole === "individual_investor") {
      registerUserPayload = {
        userid: authToken.id,
        userdetailid: authToken.userDetailId | authToken.userdetail.id,
        lang_id: String(selectedLang),
        contact: personalDetails.phn,
        countrycode: personalDetails.countryCode,
        role: userRole,
        firstname: personalDetails.fname,
        lastname: personalDetails.lname,
        residencecountry: residenceProofObj.country,
        gender: "",
        dateofbirth: null,
        linkDevice: "",
        img: "https://ucarecdn.com/1ccabd74-6863-4125-808a-b9d1315e272e/user.png",
        state: "",
        document: [
          {
            userid: authToken.id,
            userdetailid: authToken.userDetailId | authToken.userdetail.id,
            documenturl: identityProofObj.documentFileURL,
            documenttype: "Identity Proof",
            documentsubtype: identityProofObj.identityProofType,
            expirydate: String(new Date(identityProofObj.documentExpiryDate)),
            documentnumber: identityProofObj.documentNo,
            fileType: identityProofObj.documentFileType,
            fileName: identityProofObj.documentFileName,
            provience: "",
            id: identityProofObj.id,
          },
          {
            userid: authToken.id,
            userdetailid: authToken.userDetailId | authToken.userdetail.id,
            documenturl: residenceProofObj.documentFileURL,
            documenttype: "Residence Proof",
            documentsubtype: residenceProofObj.documentType,
            expirydate: String(new Date(residenceProofObj.documentDate)),
            documentnumber: "",
            fileType: residenceProofObj.documentFileType,
            fileName: residenceProofObj.documentFileName,
            provience: residenceProofObj.province,
            region: residenceProofObj.region,
            country: residenceProofObj.country,
            zipcode: residenceProofObj.zipcode,
            city: residenceProofObj.city,
            street: residenceProofObj.streetArea,
            housenumber: residenceProofObj.houseNo,
            id: residenceProofObj.id,
          },
        ],
      };
    } else if (userRole === "legalrepresent") {
      console.log("companyDetails", getSessionKey("companyDetailsPayload"));
      console.log("unoDetails", getSessionKey("uboDetails"));
      console.log("lrDetails", getSessionKey("lrDetails"));
      let tempCompArr = [];
      tempCompArr.push(getSessionKey("companyDetailsPayload"));
      registerLrUserPayload = [
        ...tempCompArr,
        ...getSessionKey("uboDetails"),
        ...getSessionKey("lrDetails"),
      ];

      registerUserPayload = {
        userid: authToken.id,
        userdetailid: authToken.userDetailId | authToken.userdetail.id,
        lang_id: String(selectedLang),
        role: "legalrepresent",
        lrpointofcontact: lrPOIDetails,
      };
    }

    console.log("registerUserPayload", registerUserPayload);
    console.log("registerLrUserPayload", registerLrUserPayload);

    const headers = {
      headers: {
        Authorization: `Bearer ${
          getSessionKey("authToken") !== null
            ? getSessionKey("authToken").accesstoken
            : ""
        }`,
        "Content-Type": "application/json",
      },
    };
    if (userRole === "legalrepresent") {
      if (getSessionKey("authToken").status.toLowerCase() === "declined") {
        axios
          .put(
            `${process.env.REACT_APP_API_URL}lrdetail`,
            registerLrUserPayload,
            headers
          )
          .then((res) => {
            if (res.data.success) {
              finalUserUpdate(headers, registerUserPayload);
            }
          })
          .catch((error) => {
            setLoading(false);
            if (
              error.response.status === 403 ||
              error.response.status === 401
            ) {
              navigate("/enter-pin");
            } else {
              console.error("Error fetching data:", error.message);
            }
          });
      } else {
        axios
          .post(
            `${process.env.REACT_APP_API_URL}lrdetail`,
            registerLrUserPayload,
            headers
          )
          .then((res) => {
            if (res.data.success) {
              finalUserUpdate(headers, registerUserPayload);
            }
          })
          .catch((error) => {
            setLoading(false);
            if (
              error.response.status === 403 ||
              error.response.status === 401
            ) {
              navigate("/enter-pin");
            } else {
              console.error("Error fetching data:", error.message);
            }
          });
      }
    } else {
      finalUserUpdate(headers, registerUserPayload);
    }
  };

  const finalUserUpdate = (headers: any, registerUserPayload: any) => {
    if (getSessionKey("authToken").status.toLowerCase() === "declined") {
      axios
        .put(
          `${process.env.REACT_APP_API_URL}userdetaildocument`,
          registerUserPayload,
          headers
        )
        .then((res) => {
          console.log("res", res);
          if (res.data.success) {
            removeSessionKey("companyDetailsPayload");
            removeSessionKey("personalDetails");
            removeSessionKey("identityProofObj");
            removeSessionKey("residenceProofObj");
            removeSessionKey("lrPOIDetails");
            removeSessionKey("companyDetails");
            removeSessionKey("uboDetails");
            removeSessionKey("lrDetails");
            setSessionKey("userRegStatus", "document approval pending");

            setLoading(false);
            navigate("/acknowledgement");
          }
        })
        .catch((error) => {
          setLoading(false);
          if (error.response.status === 403 || error.response.status === 401) {
            navigate("/enter-pin");
          } else {
            console.error("Error fetching data:", error.message);
          }
        });
    } else {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}userdetaildocument`,
          registerUserPayload,
          headers
        )
        .then((res) => {
          console.log("res", res);
          if (res.data.success) {
            removeSessionKey("companyDetailsPayload");
            removeSessionKey("personalDetails");
            removeSessionKey("identityProofObj");
            removeSessionKey("residenceProofObj");
            removeSessionKey("lrPOIDetails");
            removeSessionKey("companyDetails");
            removeSessionKey("uboDetails");
            removeSessionKey("lrDetails");
            setSessionKey("userRegStatus", "document approval pending");

            setLoading(false);
            navigate("/acknowledgement");
          }
        })
        .catch((error) => {
          setLoading(false);
          if (error.response.status === 403 || error.response.status === 401) {
            navigate("/enter-pin");
          } else {
            console.error("Error fetching data:", error.message);
          }
        });
    }
  };
  return (
    <>
      {loading ? <Loader /> : <></>}
      <header>
        <Header backLink={true}></Header>
      </header>
      <main>
        <div className="lyt-main">
          <div className={`${styles.section}`}>
            <div className={`${styles.sectionHead}`}>
              <h1 className={`${styles.pgTitle}`}>{t("terms_pgTitle")}</h1>
            </div>
            <div className="static-cont-list">
              <ul className="list">
                <li className="item">
                  <h3 className="title">{t("terms_introductionTitle")}</h3>
                  <ul className="sublist">
                    <li className="sub-item">
                      {t("terms_introductionMessage")}
                    </li>
                  </ul>
                </li>
                <li className="item">
                  <h3 className="title">{t("terms_eligibilityTitle")}</h3>
                  <ul className="sublist">
                    <li className="sub-item">
                      {t("terms_eligibilityMessage")}
                    </li>
                  </ul>
                </li>
                <li className="item">
                  <h3 className="title">{t("terms_investmentServiceTitle")}</h3>
                  <ul className="sublist">
                    <li className="sub-item">
                      {t("terms_investmentServiceMessage")}
                    </li>
                  </ul>
                </li>
                <li className="item">
                  <h3 className="title">{t("terms_obligationTitle")}</h3>
                  <p className="sub-title">{t("terms_obligationSubTitle")}</p>
                  <ul className="sublist typ-sub">
                    <li className="sub-item">
                      {t("terms_obligationMessage1")}
                    </li>
                    <li className="sub-item">
                      {t("terms_obligationMessage2")}
                    </li>
                    <li className="sub-item">
                      {t("terms_obligationMessage3")}
                    </li>
                  </ul>
                </li>
                <li className="item">
                  <h3 className="title">{t("terms_contentTitle")}</h3>
                  <ul className="sublist">
                    <li className="sub-item">{t("terms_contentMessage")}</li>
                  </ul>
                </li>
                <li className="item">
                  <h3 className="title">{t("terms_communicationTitle")}</h3>
                  <ul className="sublist">
                    <li className="sub-item">
                      {t("terms_communicationMessage")}
                    </li>
                  </ul>
                </li>
                <li className="item">
                  <h3 className="title">{t("terms_advisorTitle")}</h3>
                  <ul className="sublist">
                    <li className="sub-item">{t("terms_advisorMessage")}</li>
                  </ul>
                </li>
                <li className="item">
                  <h3 className="title">{t("terms_popupTitle")}</h3>
                  <ul className="sublist">
                    <li className="sub-item">{t("terms_popupMessage")}</li>
                  </ul>
                </li>
                <li className="item">
                  <h3 className="title">{t("terms_otpTitle")}</h3>
                  <ul className="sublist">
                    <li className="sub-item">{t("terms_otpMessage")}</li>
                  </ul>
                </li>
                <li className="item">
                  <h3 className="title">{t("terms_intellectualTitle")}</h3>
                  <ul className="sublist">
                    <li className="sub-item">
                      {t("terms_intellectualMessage")}
                    </li>
                  </ul>
                </li>
                <li className="item">
                  <h3 className="title">{t("terms_limitationTitle")}</h3>
                  <ul className="sublist">
                    <li className="sub-item">{t("terms_limitationMessage")}</li>
                  </ul>
                </li>
                <li className="item">
                  <h3 className="title">{t("terms_indemnificationTitle")}</h3>
                  <ul className="sublist">
                    <li className="sub-item">
                      {t("terms_indemnificationMessage")}
                    </li>
                  </ul>
                </li>
                <li className="item">
                  <h3 className="title">{t("terms_changesTitle")}</h3>
                  <ul className="sublist">
                    <li className="sub-item">{t("terms_changesMessage")}</li>
                  </ul>
                </li>
                <li className="item">
                  <h3 className="title">{t("terms_governingTitle")}</h3>
                  <ul className="sublist">
                    <li className="sub-item">{t("terms_governingMessage")}</li>
                  </ul>
                </li>
                <li className="item">
                  <h3 className="title">{t("terms_accessTitle")}</h3>
                  <ul className="sublist">
                    <li className="sub-item">{t("terms_accessMessage")}</li>
                  </ul>
                </li>
                <li className="item">
                  <h3 className="title">{t("terms_contactTitle")}</h3>
                  <ul className="sublist">
                    <li
                      className="sub-item"
                      dangerouslySetInnerHTML={{
                        __html: t("terms_contactMessage"),
                      }}
                    ></li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          <div className="terms-foot">
            <CheckBox
              id="terms"
              label={t("terms_checkboxLabel")}
              name="termsCondition"
              onChange={handleChange}
            ></CheckBox>
            <div className="cta">
              <Button
                btnStyle="primary"
                type="button"
                onClick={handleClick}
                disabled={!isChecked}
              >
                {t("terms_confirmBtn")}
              </Button>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default TermsConditions;
