import { useState, useEffect } from "react";
import styles from "./style.module.scss";
import PortfolioCard, {
  PortfolioCardData,
} from "src/components/portfolio-card/PortfolioCard";
import Button from "src/components/common/button/Button";
import BottomSheet from "src/components/bottom-sheet/BottomSheet";
import { useLocation, useNavigate } from "react-router-dom";
import { getSessionKey, setSessionKey } from "src/helper/sessionServices";
import Exploration from "src/components/exploration/exploration";
import { useLoader } from "src/context/LoaderContext";
import Loader from "src/components/common/loader/Loader";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import axios from "axios";
import SuccessError from "src/components/success-error/SuccessError";
import SelectClient from "src/components/select-client/SelectClient";
import AddClients from "src/components/add-clients/AddClients";

function PortfolioPage() {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation(["PortfolioDetails", "Header"]);
  const lang = getSessionKey("selectedLangCode");
  const { loading, setLoading } = useLoader();
  const [openClientDrawer, setOpenClientDrawer] = useState(false);
  const location = useLocation();
  const [portfolioData, setPortfolioData] = useState<any[]>([]);
  const [relationData, setRelationData] = useState<any>([]);
  const [settingData, setSettingData] = useState<any>([]);
  const [clientList, setClientList] = useState<any>([]);
  const [selectedClient, setSelectedClient] = useState<any>({});
  const [clientSearchData, setClientSearchData] = useState<any[]>([]);
  useEffect(() => {
    i18next.changeLanguage(lang);

    if (getSessionKey("userData")?.access_group === "advanced") {
      setLoading(true);
      axios
        .get(
          `${process.env.REACT_APP_API_URL}portfolio?userid=${
            getSessionKey("userData").id
          }`,
          {
            headers: {
              Authorization: `Bearer ${
                getSessionKey("authToken") !== null
                  ? getSessionKey("authToken").accesstoken
                  : ""
              }`,
            },
          }
        )
        .then((response) => {
          setLoading(false);
          setPortfolioData(response?.data?.data);
        })
        .catch((error) => {
          setLoading(false);
          if (error.response.status === 403 || error.response.status === 401) {
            navigate("/enter-pin");
          } else {
            console.error("Error fetching data:", error.message);
          }
        });
    }

    if (getSessionKey("userRole") === "advisor") {
      setLoading(true);
      axios
        .get(`${process.env.REACT_APP_API_URL}relation`, {
          headers: {
            Authorization: `Bearer ${
              getSessionKey("authToken") !== null
                ? getSessionKey("authToken").accesstoken
                : ""
            }`,
          },
        })
        .then((response) => {
          setRelationData(response?.data?.data);
        })
        .catch((error) => {
          if (error.response.status === 403 || error.response.status === 401) {
            navigate("/enter-pin");
          } else {
            console.error("Error fetching data:", error.message);
          }
        });

      axios
        .get(`${process.env.REACT_APP_API_URL}setting`, {
          headers: {
            Authorization: `Bearer ${
              getSessionKey("authToken") !== null
                ? getSessionKey("authToken").accesstoken
                : ""
            }`,
          },
        })
        .then((response) => {
          setSettingData(response?.data?.data);
        })
        .catch((error) => {
          if (error.response.status === 403 || error.response.status === 401) {
            navigate("/enter-pin");
          } else {
            console.error("Error fetching data:", error.message);
          }
        });
    }
  }, []);

  useEffect(() => {
    if (relationData.length > 0 && settingData.length > 0) {
      let temp: any = JSON.parse(JSON.stringify(relationData)).filter(
        (obj: any) => obj.requestStatus === "approved"
      );
      console.log(temp, "Relation data");
      temp.map((item: any) => {
        settingData.forEach((element: any) => {
          if (item?.investor?.id === element?.investorId) {
            item["viewChat"] = element?.viewChat;
            item["participateinChat"] = element?.participateinChat;
            item["expressInterest"] = element?.expressInterest;
            item["viewPortfolio"] = element?.viewPortfolio;
          }
        });
      });
      setLoading(false);
      setClientList(temp);
      setClientSearchData(temp);
      setSessionKey("relationData", temp);
      // console.log('temp', temp)
      if (getSessionKey("selectedClient") !== null) {
        const filteredClient = temp.filter((item: any) => {
          return item.id === getSessionKey("selectedClient")?.id;
        });
        setSelectedClient(filteredClient[0]);
        handleProceedToGetPortfolio(filteredClient[0]);
      }
    } else {
      setLoading(false);
    }
  }, [relationData, settingData]);

  const [searchTerm, setSearchTerm] = useState("");
  useEffect(() => {
    if (searchTerm.length >= 3) {
      let tempClientArr = [];
      tempClientArr = clientSearchData.filter((item) => {
        return item?.investor?.role === "legalrepresent"
          ? item?.investor?.userLrdetail[0]?.companyname
              .toLowerCase()
              .includes(searchTerm.toLowerCase())
          : item?.investor?.userdetail?.firstname
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
              item?.investor?.userdetail?.lastname
                .toLowerCase()
                .includes(searchTerm.toLowerCase());
      });
      setClientSearchData(tempClientArr);
    } else {
      setClientSearchData(clientList);
    }
  }, [searchTerm]);

  const [bsBtnState, setBSbtnState] = useState(true);

  const handleRadio = (e: any, item: any) => {
    setSelectedClient(item);
    setBSbtnState(false);
  };

  const handleSelectClient = () => {
    setOpenClientDrawer(true);
  };

  const handleProceedToGetPortfolio = (item: any) => {
    setSessionKey("selectedClient", item);
    setOpenClientDrawer(false);
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_API_URL}portfolio?investorid=${item?.investor?.id}`,
        {
          headers: {
            Authorization: `Bearer ${
              getSessionKey("authToken") !== null
                ? getSessionKey("authToken").accesstoken
                : ""
            }`,
          },
        }
      )
      .then((response) => {
        setLoading(false);
        setPortfolioData(response?.data?.data);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 403 || error.response.status === 401) {
          navigate("/enter-pin");
        } else {
          console.error("Error fetching data:", error.message);
        }
      });
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          {getSessionKey("userRole") === "advisor" &&
          (getSessionKey("userData")?.status === "document approval pending" ||
            getSessionKey("userData")?.status === "document reject") ? (
            <>
              <div className="blank-wrap">
                <Exploration
                  buttonText={t("portfoliodetails_drawerProceedBtn")}
                  navigationLink="/impact"
                  logoURL="/assets/icons/kyc-pending.svg"
                  description={t("acknowledgement_kycTitle")}
                />
              </div>
            </>
          ) : getSessionKey("userRole") === "advisor" &&
            getSessionKey("selectedClient") !== null &&
            !getSessionKey("selectedClient")?.viewPortfolio ? (
            <>
              <div className="blank-wrap">
                <SuccessError
                  title={t("portfoliodetails_unauthorizedAccessTitle")}
                  content={t("portfoliodetails_unauthorizedAccessSubTitle")}
                  type="account-error"
                  showBtn={true}
                  handleSelectClient={handleSelectClient}
                />
              </div>
            </>
          ) : getSessionKey("userRole") === "explorer" &&
            getSessionKey("authToken") === null ? (
            <div className="blank-wrap">
              <Exploration
                buttonText={t("portfoliodetails_registerTitle")}
                navigationLink="/login"
                logoURL="/assets/logos/explore-portfolio.png"
                description={t("portfoliodetails_registerSubTitle")}
              />
            </div>
          ) : getSessionKey("userData")?.status === "freeze" &&
            getSessionKey("authToken") !== null ? (
            <div className="blank-wrap">
              <SuccessError
                title={t("portfoliodetails_frozenAccountTitle")}
                content={t("portfoliodetails_frozenAccountSubTitle")}
                type="account-error"
              />
            </div>
          ) : getSessionKey("userData")?.access_group === "intermediate" &&
            getSessionKey("userRole") !== "advisor" &&
            getSessionKey("authToken") !== null ? (
            <div className="blank-wrap">
              <Exploration
                buttonText={t("portfoliodetails_exploreViewBtn")}
                navigationLink="/opportunities"
                logoURL="/assets/logos/explore-portfolio.png"
                description={t("portfoliodetails_exploreTitle")}
              />
            </div>
          ) : getSessionKey("userRole") === "advisor" &&
            relationData.length === 0 ? (
            <div className="blank-wrap">
              <AddClients />
            </div>
          ) : getSessionKey("userRole") === "advisor" &&
            getSessionKey("selectedClient") === null ? (
            <div className="blank-wrap">
              <SelectClient handleSelectClient={handleSelectClient} />
            </div>
          ) : (
            <>
              <div>
                <div className="lyt-main typ-main">
                  <section>
                    <div className="bs-section typ-depth">
                      <div className="sec-head">
                        <div className={`${styles.headWrap}`}>
                          {getSessionKey("userRole") === "advisor" ? (
                            <>
                              <h2 className="cm-sec-title">
                                {`${
                                  getSessionKey("selectedClient")?.investor
                                    ?.role === "legalrepresent"
                                    ? getSessionKey("selectedClient")?.investor
                                        ?.userLrdetail[0]?.companyname
                                    : getSessionKey("selectedClient")?.investor
                                        ?.userdetail?.firstname
                                }${" "}
                              ${
                                getSessionKey("selectedClient")?.investor
                                  ?.role === "legalrepresent"
                                  ? ""
                                  : getSessionKey("selectedClient")?.investor
                                      ?.userdetail?.lastname
                              }`}
                              </h2>
                              <Button
                                btnStyle="text"
                                onClick={() => setOpenClientDrawer(true)}
                              >
                                {t("portfoliodetails_editBtn")}
                              </Button>
                            </>
                          ) : (
                            <>
                              <h2 className="cm-sec-title">
                                {t("portfoliodetails_yourPortfolio")}
                              </h2>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="sec-cont">
                        <div className={`${styles.portfolioList}`}>
                          {portfolioData.map((item, index) => {
                            return (
                              <div key={index} className={`${styles.item}`}>
                                <PortfolioCard {...item}></PortfolioCard>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </>
          )}
          <BottomSheet
            open={openClientDrawer}
            primaryBtnText={t("portfoliodetails_drawerProceedBtn")}
            title={t("portfoliodetails_drawerSelectTitle")}
            subText={t("portfoliodetails_drawerSelectSubTitle")}
            size="large"
            setOpen={setOpenClientDrawer}
            btnOnClick={() => {
              handleProceedToGetPortfolio(selectedClient);
            }}
            btnDisabled={bsBtnState}
          >
            <div className="bs-form">
              <div className={`${styles.clientDrawer}`}>
                <div className="search-wrap">
                  <span className="search-icon"></span>
                  <input
                    type="text"
                    placeholder={t("portfoliodetails_searchPlaceholder")}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <button
                    className="close-btn"
                    type="button"
                    onClick={() => setSearchTerm("")}
                  >
                    <i></i>
                  </button>
                </div>
                <div className={`${styles.selectionWrap}`}>
                  <ul className={`${styles.clientList}`}>
                    {clientSearchData.map((item: any, index: number) => {
                      return (
                        <li key={index} className={`${styles.clientItem}`}>
                          <input
                            onChange={(e) => handleRadio(e, item)}
                            type="radio"
                            name="client-list"
                            id={item.id}
                            checked={
                              item.id === selectedClient.id &&
                              item.viewPortfolio === true
                            }
                            disabled={!item.viewPortfolio ? true : false}
                          />
                          <label htmlFor={item.id}>
                            <span className={`${styles.client}`}>
                              {item?.investor?.role === "legalrepresent"
                                ? `${item?.investor?.userLrdetail[0]?.companyname}`
                                : `${
                                    item?.investor?.userdetail?.firstname
                                  }${" "}${
                                    item?.investor?.userdetail?.lastname
                                  }`}
                            </span>
                            <span className={`${styles.clientCode}`}></span>
                          </label>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </BottomSheet>
        </>
      )}
    </>
  );
}

export default PortfolioPage;
