export const optionCountry = [
  { value: "BE", label: "Belgium" },
  { value: "BG", label: "Bulgaria" },
  { value: "CZ", label: "Czech Republic" },
  { value: "DK", label: "Denmark" },
  { value: "DE", label: "Germany" },
  { value: "EE", label: "Estonia" },
  { value: "IE", label: "Ireland" },
  { value: "GR", label: "Greece" },
  { value: "ES", label: "Spain" },
  { value: "FR", label: "France" },
  { value: "HR", label: "Croatia" },
  { value: "IT", label: "Italy" },
  { value: "CY", label: "Cyprus" },
  { value: "LV", label: "Latvia" },
  { value: "LT", label: "Lithuania" },
  { value: "LU", label: "Luxembourg" },
  { value: "HU", label: "Hungary" },
  { value: "MT", label: "Malta" },
  { value: "NL", label: "Netherlands" },
  { value: "AT", label: "Austria" },
  { value: "PL", label: "Poland" },
  { value: "PT", label: "Portugal" },
  { value: "RO", label: "Romania" },
  { value: "SI", label: "Slovenia" },
  { value: "SK", label: "Slovakia" },
  { value: "FI", label: "Finland" },
  { value: "SE", label: "Sweden" },
  { value: "GB", label: "United Kingdom" },
  { value: "CH", label: "Switzerland" },
];
