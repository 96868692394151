import "./App.css";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import ImpactPage from "./pages/impact/ImpactPage";
import PortfolioPage from "./pages/portfolio/PortfolioPage";
import OpportunitiesPage from "./pages/opportunities/OpportunitiesPage";
import ChatPage from "./pages/chat/ChatPage";
import NewsListing from "./pages/news-listing/NewsListing";
import ReportsListing from "./pages/reports-listing/ReportsListing";
import InsightListing from "./pages/insight-listing/InsightListing";
import NewsDetail from "./pages/news-detail/NewsDetail";
import InsightDetail from "./pages/insight-detail/InsightDetail";
import ReportDetails from "./pages/report-details/ReportDetails";
import NavBar from "./components/common/navbar/NavBar";
import Header from "./components/common/header/Header";
import Login from "./pages/login/Login";
import SignupLanding from "./pages/signup/common/landing/SignupLanding";
import SignupIntro from "./pages/signup/investor/signup-intro/SignupIntro";
import Signup from "./pages/signup/Signup";
import OtpPage from "./pages/otp/OtpPage";
import DetailsForm from "./pages/signup/DetailsForm";
import PersonalDetails from "./pages/signup/common/personal-detail/PersonalDetails";
import IdentityProof from "./pages/signup/common/identity-proof/IdentityProof";
import ResidenceProof from "./pages/signup/common/residence-proof/ResidenceProof";
import ConfirmDetails from "./pages/signup/common/confirm-details/ConfirmDetails";
import CompanyDetails from "./pages/signup/legal-entity/company-details/CompanyDetails";
import ContactDetails from "./pages/signup/legal-entity/contact-details/ContactDetails";
import UboDetails from "./pages/signup/legal-entity/ubo-details/UboDetails";
import LegalRepresentativeDetail from "./pages/signup/legal-entity/legal-representative-detail/LegalRepresentativeDetail";
import Notification from "./pages/notifications/Notification";
import PortfolioDetail from "./pages/portfolio-detail/PortfolioDetail";
import CompanyDetailsPage from "./pages/company-details/CompanyDetails";
import ProfileSettings from "./pages/profile-settings/ProfileSettings";
import AccountDetails from "./pages/account-details/AccountDetails";
import AddAdvisor from "./pages/add-advisor/AddAdvisor";
import Approval from "./pages/approval/Approval";
import LanguageSelection from "./pages/language-selection/LanguageSelection";
import PrivacySecurity from "./pages/privacy-security/PrivacySecurity";
import CustomerSupport from "./pages/customer-support/CustomerSupport";
import Onboarding from "./pages/onboarding/Onboarding";
import PageListing from "./pages/pages";
import Acknowledgement from "./pages/acknowledgement-page/Acknowledgement";
import { Suspense, useEffect, useMemo } from "react";
import ActiveDevices from "./pages/active-devices/ActiveDevices";
import AdvisorIntro from "./pages/advisor-intro/AdvisorIntro";
import TermsConditions from "./pages/terms-conditions/TermsConditions";
import HighlightDetail from "./pages/highlight-details/HighlightDetails";
import HighlightListing from "./pages/highlight-listing/HighlightListing";
import HighlightPage from "./pages/highlights/HighlightPage";
import SelectClient from "./components/select-client/SelectClient";
import NoteDrawer from "./components/note-drawer/NoteDrawer";
import AccordionModule from "./components/accordion-module/AccordionModule";
import ProfileUpdatePage from "./pages/profile-update/ProfileUpdate";
import ProfileDrawer from "./components/profile-drawer/ProfileDrawer";
import CreatePin from "./pages/profile-update/CreatePin";
import AddProfilePicture from "./pages/profile-update/AddProfilePicture";
import ProfileAddAdvisor from "./pages/profile-update/ProfileAddAdvisor";
import AddClient from "./pages/add-client/AddClient";
import ConfirmClient from "./pages/add-client/confirm-client/ConfirmClient";
import AdvisorClientPage from "./pages/advisor-client/AdvisorClientPage";
import ApprovalPage from "./pages/approval-page/ApprovalPage";
import AdvisorDetails from "./pages/advisor-details/AdvisorDetails";
import AdvisorList from "./pages/advisor-list/AdvisorList";
import DeletePage from "./pages/delete-page/DeletePage";
import InterestPage from "./pages/express-interest/InterestPage";
import ChatMessagePage from "./pages/chat-message-page/ChatMessagePage";
import ConfirmRequest from "./pages/chat-confirm-request/ConfirmRequest";
import RequestDetails from "./pages/chat-request-details/RequestDetails";
import ChatDetails from "./pages/chat-details/ChatDetails";
import ClientApprove from "./pages/client-approval/clientApprove";
import ClientReject from "./pages/client-approval/clientReject";
import UpdateProfileDetails from "./components/updateProfileDetails/UpdateProfileDetails";
import UpdateEmailDetails from "./components/updateProfileDetails/UpdateEmailDetails";
import AdvisorClientRelation from "./pages/socket-client/AdvisorClientRelation";
import ChatDetailsSocket from "./pages/socket-client/ChatDetailsSocket";
import { Provider } from "react-redux";
import { store } from "./store";
import UserHighlights from "./pages/user-highlights/UserHighlights";
import ExistingPin from "./pages/existing-pin/ExistingPin";
import CreateNewPin from "./pages/create-new-pin/CreateNewPin";
import {
  clearSession,
  getSessionKey,
  logoutSession,
  setSessionKey,
} from "./helper/sessionServices";
import EnterPin from "./pages/enter-pin/EnterPin";
import AiMessage from "./pages/ai-message/AiMessaage";
import ProfileAddClients from "./pages/profile-update/ProfileAddClients";
import axios from "axios";
import { headers } from "./helper/authToken";

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

try {
  console.log = function () {};
  console.info = function () {};
  console.warn = function () {};
  console.error = function () {};
} catch (e) {}

function App() {
  const { pathname } = useLocation();
  let query = useQuery();
  const parentUrl = query.get("parentUrl");
  console.log("parentUrl", parentUrl);
  if (parentUrl) {
    setSessionKey("selectedLangCode", "en");
    setSessionKey("selectedLang", "1");
    setSessionKey("authUser", parentUrl);
  }

  const navigate = useNavigate();
  useEffect(() => {
    const authUser = getSessionKey("authUser");
    const authToken = getSessionKey("authToken");
    const userData = getSessionKey("userData");
    if (authUser !== null) {
      navigate("/login");
    } else if (authToken !== null && userData !== null) {
      if (userData?.pin !== null) {
        navigate("/enter-pin"); //comment this when local development
      }
    } else if (
      authToken !== null &&
      userData === null &&
      getSessionKey("authToken").status.toLowerCase() !== "pending" &&
      getSessionKey("authToken").status.toLowerCase() !== "declined"
    ) {
      navigate("/impact");
    } else if (authToken === null && getSessionKey("selectedLang") !== null) {
      navigate("/login");
    } else if (authToken === null) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    const validateUserSession = async () => {
      const authToken = getSessionKey("authToken");
      const userData = getSessionKey("userData");

      if (authToken && userData) {
        const userStatus = userData.status?.toLowerCase();

        if (
          [
            "document approval pending",
            "approved",
            "freeze",
            "declined",
          ].includes(userStatus)
        ) {
          try {
            const response = await axios.get(
              `${process.env.REACT_APP_API_URL}user?id=${userData.id}`,
              {
                headers: {
                  Authorization: `Bearer ${authToken.accesstoken}`,
                },
              }
            );

            const userExistData = response.data.data;

            if (userExistData.length === 0) {
              clearSession();
              navigate("/login");
            } else {
              setSessionKey("userData", userExistData[0]);
              setSessionKey("userExistData", userExistData);

              if (userExistData[0].status === "declined") {
                logoutSession(navigate);
              }
            }
          } catch (error: any) {
            console.error("Error fetching user data:", error.message);

            if (error.response) {
              if (
                error.response.status === 403 ||
                error.response.status === 401
              ) {
                navigate("/enter-pin");
              } else if (error.response.status === 405) {
                clearSession();
                navigate("/");
              }
            }
          }
        }
      }
    };

    validateUserSession();
  }, [navigate]);

  return (
    <Provider store={store}>
      <>
        {pathname === "/impact" ||
        pathname === "/select-client" ||
        pathname === "/portfolios" ||
        pathname === "/opportunities" ||
        pathname === "/chat" ? (
          <header>
            <Header
              showProfile={true}
              showChat={true}
              actionItems={["notification"]}
            ></Header>
          </header>
        ) : (
          <></>
        )}

        <Routes>
          <Route path="/" element={<Onboarding />}></Route>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/otp" element={<OtpPage />}></Route>
          <Route path="/signup" element={<Signup />}>
            <Route path="" element={<SignupLanding />} />
            <Route path="intro" element={<SignupIntro />} />
            <Route path="details-form" element={<DetailsForm />}>
              <Route path="" element={<PersonalDetails />} />
              <Route path="personal-details" element={<PersonalDetails />} />
              <Route
                path="identity-proof"
                element={<IdentityProof currentStep={2} />}
              />
              <Route
                path="residence-proof"
                element={<ResidenceProof currentStep={3} />}
              />
              <Route
                path="company-details"
                element={<CompanyDetails currentStep={1} />}
              />
              <Route
                path="ubo-details"
                element={<UboDetails currentStep={2} />}
              />
              <Route
                path="legal-representative-details"
                element={<LegalRepresentativeDetail currentStep={3} />}
              />
              <Route
                path="contact-details"
                element={<ContactDetails currentStep={4} />}
              />
            </Route>
            <Route path="confirm-details" element={<ConfirmDetails />} />
          </Route>
          <Route path="/enter-pin" element={<EnterPin />}></Route>
          <Route path="/add-client" element={<AddClient />}></Route>
          <Route path="confirm-client" element={<ConfirmClient />} />
          <Route path="/profile-update" element={<ProfileUpdatePage />}>
            <Route path="" element={<ProfileDrawer />} />
            <Route path="create-pin" element={<CreatePin />} />
            <Route
              path="add-profile-picture"
              element={<AddProfilePicture currentStep={2} />}
            ></Route>
            <Route
              path="profile-add-advisor"
              element={<ProfileAddAdvisor currentStep={3} />}
            ></Route>
            <Route
              path="profile-add-clients"
              element={<ProfileAddClients currentStep={3} />}
            ></Route>
            <Route path="confirm-details" element={<ConfirmDetails />} />
          </Route>
          <Route path="/acknowledgement" element={<Acknowledgement />}></Route>
          <Route path="/notifications" element={<Notification />}></Route>
          <Route path="/impact" element={<ImpactPage />}></Route>
          <Route path="/portfolios" element={<PortfolioPage />}></Route>
          <Route path="/portfolio-detail" element={<PortfolioDetail />}></Route>
          <Route path="/opportunities" element={<OpportunitiesPage />}></Route>
          <Route
            path="/company-details"
            element={<CompanyDetailsPage />}
          ></Route>
          <Route path="/chat" element={<ChatPage />}></Route>
          <Route path="/highlights" element={<HighlightPage />}></Route>
          {
            <Route
              path="/highlight-list"
              element={<HighlightListing />}
            ></Route>
          }
          <Route path="/news-list" element={<NewsListing />}></Route>
          <Route path="/report-list" element={<ReportsListing />}></Route>
          <Route path="/insight-list" element={<InsightListing />}></Route>
          <Route path="/highlight-detail" element={<HighlightDetail />}></Route>
          <Route path="/news-detail" element={<NewsDetail />}></Route>
          <Route path="/insight-detail" element={<InsightDetail />}></Route>
          <Route path="/report-detail" element={<ReportDetails />}></Route>
          <Route path="/profile-settings" element={<ProfileSettings />}></Route>
          <Route path="/account-details" element={<AccountDetails />}></Route>
          <Route path="/add-advisor" element={<AddAdvisor />}></Route>
          <Route path="/approval" element={<Approval />}></Route>
          <Route path="/approval-page" element={<ApprovalPage />}></Route>
          <Route
            path="/language-selection"
            element={<LanguageSelection />}
          ></Route>
          <Route path="/privacy-security" element={<PrivacySecurity />}></Route>
          <Route path="/customer-support" element={<CustomerSupport />}></Route>
          <Route path="/active-devices" element={<ActiveDevices />}></Route>
          <Route path="/onboarding" element={<Onboarding />}></Route>
          <Route path="/pages" element={<PageListing />}></Route>
          <Route path="/delete-page" element={<DeletePage />}></Route>
          <Route path="/advisor-intro" element={<AdvisorIntro />}></Route>
          <Route path="/terms-conditions" element={<TermsConditions />}></Route>
          <Route path="/select-client" element={<SelectClient />}></Route>
          <Route path="/note-drawer" element={<NoteDrawer />}></Route>
          <Route path="/accordion-module" element={<AccordionModule />}></Route>
          <Route path="/advisor-client" element={<AdvisorClientPage />}></Route>
          <Route path="/advisor-details" element={<AdvisorDetails />}></Route>
          <Route path="/advisor-list" element={<AdvisorList />}></Route>
          <Route path="/express-interest" element={<InterestPage />}></Route>
          <Route
            path="/chat-message-page"
            element={<ChatMessagePage />}
          ></Route>
          <Route
            path="/accordion-module/update-profile"
            element={<UpdateProfileDetails />}
          ></Route>
          <Route
            path="/accordion-module/update-email"
            element={<UpdateEmailDetails />}
          ></Route>

          <Route
            path="/chat-confirm-request"
            element={<ConfirmRequest />}
          ></Route>
          <Route
            path="/chat-request-details"
            element={<RequestDetails />}
          ></Route>
          <Route path="/chat-details" element={<ChatDetails />}></Route>
          <Route path="/ai-message" element={<AiMessage />}></Route>
          <Route path="/client-approve" element={<ClientApprove />}></Route>
          <Route path="/client-reject" element={<ClientReject />}></Route>

          <Route path="/user-highlights" element={<UserHighlights />}></Route>
          <Route path="/check-existing-pin" element={<ExistingPin />}></Route>
          <Route path="/create-pin" element={<CreateNewPin />}></Route>
          <Route
            path="/advisor-client-relation"
            element={<AdvisorClientRelation />}
          ></Route>
          <Route
            path="/chat-details-socket"
            element={<ChatDetailsSocket />}
          ></Route>
        </Routes>

        {pathname === "/impact" ||
        pathname === "/select-client" ||
        pathname === "/portfolios" ||
        pathname === "/opportunities" ||
        pathname === "/chat" ? (
          <footer>
            <NavBar></NavBar>
          </footer>
        ) : (
          <></>
        )}
      </>
    </Provider>
  );
}

export default App;
