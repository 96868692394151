import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "src/components/common/header/Header";
import Button from "src/components/common/button/Button";
import ProfileCard, {
  ProfileData,
} from "src/components/profile-card/ProfileCard";
import axios from "axios";
import { getSessionKey } from "src/helper/sessionServices";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
// import { useLoader } from "src/context/LoaderContext";
import { useLoader } from "src/context/LoaderContext";
// import { useLoader } from "src/context/LoaderContext";
export const StepContext = React.createContext<{
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
} | null>(null);
function AddProfilePicture({ currentStep }: { currentStep: number }) {
  const { i18n, t } = useTranslation(["ProfileSettings"]);
  const lang = getSessionKey("selectedLangCode");
  const navigate = useNavigate();
  const userData = getSessionKey("userData");
  const filterItemsByType = (data: any, type: string) => {
    return data.userdetail.userlrdetail.filter(
      (item: any) => item.detailtype === type
    );
  };

  const companyDetails = filterItemsByType(userData, "company")[0];

  const handleSkipClick = () => {
    navigate("/enter-pin");
    // navigate(
    //   (userData?.role).toLowerCase() === "advisor"
    //     ? "/profile-update/profile-add-clients"
    //     : "/profile-update/profile-add-advisor"
    // );
  };

  const profileImgData = getSessionKey("profilePicData");
  const handleClick = () => {
    //navigate("/profile-update/profile-add-advisor");
    const userData = getSessionKey("userData");
    const profileImgData = getSessionKey("profilePicData");
    const payload = {
      id: userData?.id,
      userdetail: {
        img: profileImgData?.data.fileurl
          ? profileImgData?.data.fileurl
          : userData?.userdetail?.img,
      },
    };
    axios
      .put(`${process.env.REACT_APP_API_URL}user`, payload, {
        headers: {
          Authorization: `Bearer ${getSessionKey("authToken").accesstoken}`,
        },
      })
      .then((res) => {
        if (res.data.success) {
          navigate("/enter-pin");
        } else {
        }
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          navigate("/enter-pin");
        } else {
          console.error("Error fetching data:", error.message);
        }
      });
  };

  const [profileData, setProfileData] = useState<ProfileData>();
  useEffect(() => {
    i18next.changeLanguage(lang);
  }, []);

  useEffect(() => {
    if (userData.role !== "legalrepresent") {
      setProfileData({
        id: userData.userdetail.userid,
        firstName: userData.userdetail.firstname,
        lastName: userData.userdetail.lastname,
        profileImage: userData.userdetail.img,
      });
    } else {
      setProfileData({
        id: userData.userdetail.userid,
        firstName: companyDetails.companyname,
        lastName: "",
        profileImage:
          userData.userdetail.img === null
            ? "https://ucarecdn.com/1ccabd74-6863-4125-808a-b9d1315e272e/user.png"
            : userData.userdetail.img,
      });
    }
  }, []);

  const [step, setStep] = useState(currentStep);
  return (
    <StepContext.Provider value={{ step, setStep }}>
      <header>
        <Header
          backLink={true}
          showProgress={true}
          currentStep={step}
          totalSteps={2}
        ></Header>
      </header>

      <div className="lyt-main">
        <div className={`${styles.section}`}>
          <div className={`${styles.sectionHead}`}>
            <div className={`${styles.titleWrap}`}>
              <h1 className={`${styles.pgTitle}`}>
                {t("profilesettings_addProfilePgTitle")}
              </h1>
              <button
                type="button"
                className="edit-btn"
                onClick={handleSkipClick}
              >
                {t("profilesettings_skipBtn")}
              </button>
            </div>
            <p className={`${styles.subTitle}`}>
              {t("profilesettings_addProfilePgSubTitle")}
            </p>
          </div>
          <div className={`${styles.sectionCont}`}>
            <div className="profile-card">
              <ProfileCard {...profileData}></ProfileCard>
            </div>
          </div>
        </div>
        <div className={`${styles.footWrap}`}>
          <Button btnStyle="primary" onClick={handleClick}>
            {t("profilesettings_proceedBtn")}
          </Button>
        </div>
      </div>
    </StepContext.Provider>
  );
}

export default AddProfilePicture;
