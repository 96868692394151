import { FormControl, TextField } from "@mui/material";
import styles from "./style.module.scss";
import Button from "src/components/common/button/Button";
import PhoneNumber from "src/components/phone-number/PhoneNumber";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { getSessionKey, setSessionKey } from "src/helper/sessionServices";
import { useEffect } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

export interface Values {
  countryCode: string;
  phn: string;
}

function PersonalDetails() {
  const { i18n, t } = useTranslation(["PersonalDetails"]);
  const lang = getSessionKey("selectedLangCode");
  const navigate = useNavigate();
  const validationSchema = yup.object({
    fname: yup.string().required(`${t("personaldetails_validationFirstName")}`),
    lname: yup.string().required(`${t("personaldetails_validationLastName")}`),
    phn: yup
      .number()
      .required(`${t("personaldetails_validationMobileNumber")}`),
    // .test("phone-num-length", "Invalid phone number", (value) => {
    //   return String(value).length === 10;
    // }),
  });
  const otpData = {
    type: "mobile",
    iconName: "mobile",
    value: "xxxxxxxxxx",
    source: "personal-detail",
    countryCode: "+91",
  };
  const initialValues: Values = {
    countryCode: "+91",
    phn: "",
  };
  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      fname: "",
      lname: "",
      ...initialValues,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      otpData.value = values.phn;
      otpData.countryCode = values.countryCode;
      console.log("values", values);
      if (
        (getSessionKey("mobileVerified") &&
          getSessionKey("personalDetails").phn === values.phn) ||
        getSessionKey("authUser") !== null
      ) {
        setSessionKey("personalDetails", JSON.stringify(values));
        navigate("/signup/details-form/identity-proof");
      } else {
        setSessionKey("personalDetails", JSON.stringify(values));
        const otpPayload = {
          contact: `${String(values.countryCode)}${String(values.phn)}`,
          sendby: "sms",
          type: "verification",
        };
        axios
          .post(`${process.env.REACT_APP_API_URL}auth/otp`, otpPayload)
          .then((otpRes) => {
            if (otpRes.data.success) {
              navigate("/otp", { state: otpData });
            }
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
            if (error.response.data.error === "Detail Already verified") {
              setSessionKey("mobileVerified", "true");
              navigate("/signup/details-form/identity-proof");
            } else {
              console.log("mobile already verified");
            }
            // remove below navigation after mobile otp service integrated
            // navigate("/otp", { state: otpData });
          });
      }
    },
  });

  useEffect(() => {
    i18next.changeLanguage(lang);
  }, []);
  useEffect(() => {
    const userDetails =
      getSessionKey("personalDetails") !== null
        ? getSessionKey("personalDetails")
        : getSessionKey("userExistData");
    //const userExistData = getSessionKey("userExistData")

    console.log("userDetails", userDetails);
    if (userDetails !== null) {
      formik.setFieldValue(
        "fname",
        userDetails.fname
          ? userDetails.fname
          : userDetails[0].userdetail.firstname
      );
      formik.setFieldValue(
        "lname",
        userDetails.lname
          ? userDetails.lname
          : userDetails[0].userdetail.lastname
      );
      formik.setFieldValue(
        "phn",
        userDetails.phn ? userDetails.phn : userDetails[0].contact
      );
      formik.setFieldValue(
        "countryCode",
        userDetails?.countryCode
          ? userDetails.countryCode
          : userDetails[0].countrycode
      );
      formik.handleBlur("phn");
      //formik.handleChange("phn")
      //formik.touched.phn
      setTimeout(() => {
        formik.validateForm();
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="lyt-main typ-details-form">
      <div className={`${styles.section}`}>
        <div className={`${styles.sectionHead}`}>
          <h1 className={`${styles.pgTitle}`}>{t("personaldetails_title")}</h1>
        </div>
        <div className="bs-form">
          <form onSubmit={formik.handleSubmit}>
            <div className="form-body">
              <div className="form-group">
                <FormControl variant="standard">
                  <TextField
                    id="fname"
                    label={t("personaldetails_firstName")}
                    placeholder={t("personaldetails_firstName")}
                    name="fname"
                    value={formik.values.fname}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.fname && Boolean(formik.errors.fname)}
                    helperText={formik.touched.fname && formik.errors.fname}
                  />
                </FormControl>
              </div>
              <div className="form-group">
                <FormControl variant="standard">
                  <TextField
                    id="lname"
                    label={t("personaldetails_lastName")}
                    placeholder={t("personaldetails_lastName")}
                    name="lname"
                    value={formik.values.lname}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.lname && Boolean(formik.errors.lname)}
                    helperText={formik.touched.lname && formik.errors.lname}
                  />
                </FormControl>
              </div>
              <div className="form-group typ-phone">
                <PhoneNumber
                  id="phn"
                  value={formik.values.phn}
                  countryCodeId="countryCode"
                  formikObj={formik}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.phn && Boolean(formik.errors.phn)}
                  helperText={formik.touched.phn && formik.errors.phn}
                ></PhoneNumber>
              </div>
            </div>
            <div className="form-foot">
              <Button
                btnStyle="primary"
                type="submit"
                disabled={!formik.isValid}
              >
                {t("personaldetails_proceedBtn")}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default PersonalDetails;
