import { ReactNode, useEffect, useState } from "react";
import { FormControl, TextField } from "@mui/material";
import styles from "./style.module.scss";
import BottomSheet from "../bottom-sheet/BottomSheet";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

export interface Values {
  phn: number | string;
}

export interface PhoneValidation {
  id?: string;
  countryCodeId?: string;
  value?: number | string;
  formikObj?: any;
  onChange?: any;
  onBlur?: any;
  error?: boolean;
  helperText?: ReactNode;
}

const countryList = [
  { code: "AF", name: "Afghanistan", countryCode: "+93" },
  { code: "AL", name: "Albania", countryCode: "+355" },
  { code: "AD", name: "Andorra", countryCode: "+376" },
  { code: "AG", name: "Antigua and Barbuda", countryCode: "+1-268" },
  { code: "AI", name: "Anguilla", countryCode: "+1-264" },
  { code: "AO", name: "Angola", countryCode: "+244" },
  { code: "AQ", name: "Antarctica", countryCode: "+672" },
  { code: "AW", name: "Aruba", countryCode: "+297" },
  { code: "AM", name: "Armenia", countryCode: "+374" },
  { code: "AR", name: "Argentina", countryCode: "+54" },
  { code: "AS", name: "American Samoa", countryCode: "+1-684" },
  { code: "AT", name: "Austria", countryCode: "+43" },
  { code: "AU", name: "Australia", countryCode: "+61" },
  { code: "AZ", name: "Azerbaijan", countryCode: "+994" },
  { code: "BS", name: "Bahamas", countryCode: "+1-242" },
  { code: "BH", name: "Bahrain", countryCode: "+973" },
  { code: "BD", name: "Bangladesh", countryCode: "+880" },
  { code: "BB", name: "Barbados", countryCode: "+1-246" },
  { code: "BY", name: "Belarus", countryCode: "+375" },
  { code: "BZ", name: "Belize", countryCode: "+501" },
  { code: "BE", name: "Belgium", countryCode: "+32" },
  { code: "BF", name: "Burkina Faso", countryCode: "+226" },
  { code: "BG", name: "Bulgaria", countryCode: "+359" },
  { code: "BI", name: "Burundi", countryCode: "+257" },
  { code: "BJ", name: "Benin", countryCode: "+229" },
  { code: "BL", name: "Saint Barthelemy", countryCode: "+590" },
  { code: "BM", name: "Bermuda", countryCode: "+1-441" },
  { code: "BN", name: "Brunei Darussalam", countryCode: "+673" },
  { code: "BO", name: "Bolivia", countryCode: "+591" },
  { code: "BR", name: "Brazil", countryCode: "+55" },
  { code: "BT", name: "Bhutan", countryCode: "+975" },
  { code: "BW", name: "Botswana", countryCode: "+267" },
  { code: "CF", name: "Central African Republic", countryCode: "+236" },
  { code: "CA", name: "Canada", countryCode: "+1" },
  { code: "CC", name: "Cocos (Keeling) Islands", countryCode: "+61" },
  { code: "TD", name: "Chad", countryCode: "+235" },
  { code: "CL", name: "Chile", countryCode: "+56" },
  { code: "CN", name: "China", countryCode: "+86" },
  { code: "CO", name: "Colombia", countryCode: "+57" },
  { code: "KM", name: "Comoros", countryCode: "+269" },
  { code: "CK", name: "Cook Islands", countryCode: "+682" },
  { code: "CR", name: "Costa Rica", countryCode: "+506" },
  { code: "HR", name: "Croatia", countryCode: "+385" },
  { code: "CU", name: "Cuba", countryCode: "+53" },
  { code: "CY", name: "Cyprus", countryCode: "+357" },
  { code: "CZ", name: "Czech Republic", countryCode: "+420" },
  { code: "DK", name: "Denmark", countryCode: "+45" },
  { code: "DJ", name: "Djibouti", countryCode: "+253" },
  { code: "DO", name: "Dominican Republic", countryCode: "+1-809" },
  { code: "DM", name: "Dominica", countryCode: "+1-767" },
  { code: "DZ", name: "Algeria", countryCode: "+213" },
  { code: "EC", name: "Ecuador", countryCode: "+593" },
  { code: "EG", name: "Egypt", countryCode: "+20" },
  { code: "SV", name: "El Salvador", countryCode: "+503" },
  { code: "EE", name: "Estonia", countryCode: "+372" },
  { code: "ET", name: "Ethiopia", countryCode: "+251" },
  { code: "FI", name: "Finland", countryCode: "+358" },
  { code: "FJ", name: "Fiji", countryCode: "+679" },
  { code: "FK", name: "Falkland Islands (Malvinas)", countryCode: "+500" },
  { code: "FM", name: "Micronesia, Federated States of", countryCode: "+691" },
  { code: "FO", name: "Faroe Islands", countryCode: "+298" },
  { code: "FR", name: "France", countryCode: "+33" },
  { code: "GF", name: "French Guiana", countryCode: "+594" },
  { code: "PF", name: "French Polynesia", countryCode: "+689" },
  { code: "TF", name: "French Southern Territories", countryCode: "+262" },
  { code: "GA", name: "Gabon", countryCode: "+241" },
  { code: "GB", name: "United Kingdom", countryCode: "+44" },
  { code: "GE", name: "Georgia", countryCode: "+995" },
  { code: "GH", name: "Ghana", countryCode: "+233" },
  { code: "GI", name: "Gibraltar", countryCode: "+350" },
  { code: "GL", name: "Greenland", countryCode: "+299" },
  { code: "GM", name: "Gambia", countryCode: "+220" },
  { code: "GN", name: "Guinea", countryCode: "+224" },
  { code: "GP", name: "Guadeloupe", countryCode: "+590" },
  { code: "GQ", name: "Equatorial Guinea", countryCode: "+240" },
  { code: "GR", name: "Greece", countryCode: "+30" },
  { code: "GT", name: "Guatemala", countryCode: "+502" },
  { code: "GU", name: "Guam", countryCode: "+1-671" },
  { code: "GW", name: "Guinea-Bissau", countryCode: "+245" },
  { code: "GY", name: "Guyana", countryCode: "+592" },
  { code: "HK", name: "Hong Kong", countryCode: "+852" },
  { code: "HN", name: "Honduras", countryCode: "+504" },
  { code: "HU", name: "Hungary", countryCode: "+36" },
  { code: "IS", name: "Iceland", countryCode: "+354" },
  { code: "IN", name: "India", countryCode: "+91" },
  { code: "ID", name: "Indonesia", countryCode: "+62" },
  { code: "IR", name: "Iran", countryCode: "+98" },
  { code: "IQ", name: "Iraq", countryCode: "+964" },
  { code: "IE", name: "Ireland", countryCode: "+353" },
  { code: "IL", name: "Israel", countryCode: "+972" },
  { code: "IT", name: "Italy", countryCode: "+39" },
  { code: "JM", name: "Jamaica", countryCode: "+1-876" },
  { code: "JP", name: "Japan", countryCode: "+81" },
  { code: "JE", name: "Jersey", countryCode: "+44-1534" },
  { code: "JO", name: "Jordan", countryCode: "+962" },
  { code: "KZ", name: "Kazakhstan", countryCode: "+7" },
  { code: "KE", name: "Kenya", countryCode: "+254" },
  { code: "KI", name: "Kiribati", countryCode: "+686" },
  {
    code: "KP",
    name: "Korea, Democratic People's Republic of",
    countryCode: "+850",
  },
  { code: "KR", name: "Korea, Republic of", countryCode: "+82" },
  { code: "KW", name: "Kuwait", countryCode: "+965" },
  { code: "KG", name: "Kyrgyzstan", countryCode: "+996" },
  { code: "LA", name: "Lao People's Democratic Republic", countryCode: "+856" },
  { code: "LV", name: "Latvia", countryCode: "+371" },
  { code: "LB", name: "Lebanon", countryCode: "+961" },
  { code: "LS", name: "Lesotho", countryCode: "+266" },
  { code: "LR", name: "Liberia", countryCode: "+231" },
  { code: "LY", name: "Libya", countryCode: "+218" },
  { code: "LI", name: "Liechtenstein", countryCode: "+423" },
  { code: "LT", name: "Lithuania", countryCode: "+370" },
  { code: "LU", name: "Luxembourg", countryCode: "+352" },
  { code: "MO", name: "Macao", countryCode: "+853" },
  { code: "MG", name: "Madagascar", countryCode: "+261" },
  { code: "MW", name: "Malawi", countryCode: "+265" },
  { code: "MY", name: "Malaysia", countryCode: "+60" },
  { code: "MV", name: "Maldives", countryCode: "+960" },
  { code: "ML", name: "Mali", countryCode: "+223" },
  { code: "MT", name: "Malta", countryCode: "+356" },
  { code: "MH", name: "Marshall Islands", countryCode: "+692" },
  { code: "MQ", name: "Martinique", countryCode: "+596" },
  { code: "MR", name: "Mauritania", countryCode: "+222" },
  { code: "MU", name: "Mauritius", countryCode: "+230" },
  { code: "YT", name: "Mayotte", countryCode: "+262" },
  { code: "MX", name: "Mexico", countryCode: "+52" },
  { code: "FM", name: "Micronesia, Federated States of", countryCode: "+691" },
  { code: "MD", name: "Moldova", countryCode: "+373" },
  { code: "MC", name: "Monaco", countryCode: "+377" },
  { code: "MN", name: "Mongolia", countryCode: "+976" },
  { code: "ME", name: "Montenegro", countryCode: "+382" },
  { code: "MS", name: "Montserrat", countryCode: "+1-664" },
  { code: "MA", name: "Morocco", countryCode: "+212" },
  { code: "MZ", name: "Mozambique", countryCode: "+258" },
  { code: "MM", name: "Myanmar", countryCode: "+95" },
  { code: "NA", name: "Namibia", countryCode: "+264" },
  { code: "NR", name: "Nauru", countryCode: "+674" },
  { code: "NP", name: "Nepal", countryCode: "+977" },
  { code: "NL", name: "Netherlands", countryCode: "+31" },
  { code: "NZ", name: "New Zealand", countryCode: "+64" },
  { code: "NI", name: "Nicaragua", countryCode: "+505" },
  { code: "NE", name: "Niger", countryCode: "+227" },
  { code: "NG", name: "Nigeria", countryCode: "+234" },
  { code: "NU", name: "Niue", countryCode: "+683" },
  { code: "NF", name: "Norfolk Island", countryCode: "+672" },
  { code: "MP", name: "Northern Mariana Islands", countryCode: "+1-670" },
  { code: "NO", name: "Norway", countryCode: "+47" },
  { code: "OM", name: "Oman", countryCode: "+968" },
  { code: "PK", name: "Pakistan", countryCode: "+92" },
  { code: "PW", name: "Palau", countryCode: "+680" },
  { code: "PS", name: "Palestine", countryCode: "+970" },
  { code: "PA", name: "Panama", countryCode: "+507" },
  { code: "PG", name: "Papua New Guinea", countryCode: "+675" },
  { code: "PY", name: "Paraguay", countryCode: "+595" },
  { code: "PE", name: "Peru", countryCode: "+51" },
  { code: "PH", name: "Philippines", countryCode: "+63" },
  { code: "PL", name: "Poland", countryCode: "+48" },
  { code: "PT", name: "Portugal", countryCode: "+351" },
  { code: "PR", name: "Puerto Rico", countryCode: "+1-787" },
  { code: "QA", name: "Qatar", countryCode: "+974" },
  { code: "RE", name: "Réunion", countryCode: "+262" },
  { code: "RO", name: "Romania", countryCode: "+40" },
  { code: "RU", name: "Russian Federation", countryCode: "+7" },
  { code: "RW", name: "Rwanda", countryCode: "+250" },
  { code: "BL", name: "Saint Barthelemy", countryCode: "+590" },
  { code: "SH", name: "Saint Helena", countryCode: "+290" },
  { code: "KN", name: "Saint Kitts and Nevis", countryCode: "+1-869" },
  { code: "LC", name: "Saint Lucia", countryCode: "+1-758" },
  { code: "MF", name: "Saint Martin (French part)", countryCode: "+590" },
  { code: "SX", name: "Saint Martin (Dutch part)", countryCode: "+1-721" },
  { code: "PM", name: "Saint Pierre and Miquelon", countryCode: "+508" },
  {
    code: "VC",
    name: "Saint Vincent and the Grenadines",
    countryCode: "+1-784",
  },
  { code: "WS", name: "Samoa", countryCode: "+685" },
  { code: "SM", name: "San Marino", countryCode: "+378" },
  { code: "ST", name: "Sao Tome and Principe", countryCode: "+239" },
  { code: "SA", name: "Saudi Arabia", countryCode: "+966" },
  { code: "SN", name: "Senegal", countryCode: "+221" },
  { code: "RS", name: "Serbia", countryCode: "+381" },
  { code: "SC", name: "Seychelles", countryCode: "+248" },
  { code: "SL", name: "Sierra Leone", countryCode: "+232" },
  { code: "SG", name: "Singapore", countryCode: "+65" },
  { code: "SK", name: "Slovakia", countryCode: "+421" },
  { code: "SI", name: "Slovenia", countryCode: "+386" },
  { code: "SB", name: "Solomon Islands", countryCode: "+677" },
  { code: "SO", name: "Somalia", countryCode: "+252" },
  { code: "ZA", name: "South Africa", countryCode: "+27" },
  {
    code: "GS",
    name: "South Georgia and the South Sandwich Islands",
    countryCode: "+500",
  },
  { code: "KR", name: "South Korea", countryCode: "+82" },
  { code: "SS", name: "South Sudan", countryCode: "+211" },
  { code: "ES", name: "Spain", countryCode: "+34" },
  { code: "LK", name: "Sri Lanka", countryCode: "+94" },
  { code: "SD", name: "Sudan", countryCode: "+249" },
  { code: "SR", name: "Suriname", countryCode: "+597" },
  { code: "SZ", name: "Swaziland", countryCode: "+268" },
  { code: "SE", name: "Sweden", countryCode: "+46" },
  { code: "CH", name: "Switzerland", countryCode: "+41" },
  { code: "SY", name: "Syrian Arab Republic", countryCode: "+963" },
  { code: "TW", name: "Taiwan", countryCode: "+886" },
  { code: "TJ", name: "Tajikistan", countryCode: "+992" },
  { code: "TZ", name: "Tanzania, United Republic of", countryCode: "+255" },
  { code: "TH", name: "Thailand", countryCode: "+66" },
  { code: "TL", name: "Timor-Leste", countryCode: "+670" },
  { code: "TG", name: "Togo", countryCode: "+228" },
  { code: "TK", name: "Tokelau", countryCode: "+690" },
  { code: "TO", name: "Tonga", countryCode: "+676" },
  { code: "TT", name: "Trinidad and Tobago", countryCode: "+1-868" },
  { code: "TN", name: "Tunisia", countryCode: "+216" },
  { code: "TR", name: "Turkey", countryCode: "+90" },
  { code: "TM", name: "Turkmenistan", countryCode: "+993" },
  { code: "TC", name: "Turks and Caicos Islands", countryCode: "+1-649" },
  { code: "TV", name: "Tuvalu", countryCode: "+688" },
  { code: "UG", name: "Uganda", countryCode: "+256" },
  { code: "UA", name: "Ukraine", countryCode: "+380" },
  { code: "AE", name: "United Arab Emirates", countryCode: "+971" },
  { code: "GB", name: "United Kingdom", countryCode: "+44" },
  { code: "US", name: "United States", countryCode: "+1" },
  { code: "UY", name: "Uruguay", countryCode: "+598" },
  { code: "UZ", name: "Uzbekistan", countryCode: "+998" },
  { code: "VU", name: "Vanuatu", countryCode: "+678" },
  { code: "VE", name: "Venezuela", countryCode: "+58" },
  { code: "VN", name: "Viet Nam", countryCode: "+84" },
  { code: "WF", name: "Wallis and Futuna", countryCode: "+681" },
  { code: "EH", name: "Western Sahara", countryCode: "+212" },
  { code: "YE", name: "Yemen", countryCode: "+967" },
  { code: "ZM", name: "Zambia", countryCode: "+260" },
  { code: "ZW", name: "Zimbabwe", countryCode: "+263" },
];

function PhoneNumber(props: PhoneValidation) {
  const { i18n, t } = useTranslation(["PhoneNumber"]);
  const [openCountryDrawer, setOpenCountryDrawer] = useState(false);
  const [countryCode, setCountryCode] = useState({
    code: "IN",
    name: "India",
    countryCode: props.formikObj.values.countryCode,
  });
  const [filterCountryList, setFilterCountryList] = useState(countryList);
  const [searchValue, setSearchValue] = useState("");

  const handleRadio = (e: any, item: any) => {
    setCountryCode(item);
    props.formikObj.setFieldValue("countryCode", item.countryCode);
    setOpenCountryDrawer(false);
    setFilterCountryList(countryList);
  };

  const handleCountrySearch = (e: any) => {
    const inputValue = e.target.value.toLowerCase();
    setSearchValue(inputValue);
    setFilterCountryList(
      // eslint-disable-next-line array-callback-return
      countryList.filter((item) => {
        if (item.name.toLowerCase().startsWith(e.target.value.toLowerCase()))
          return item;
      })
    );
  };

  const handleClearSearch = () => {
    setSearchValue("");
    setFilterCountryList(countryList);
  };

  useEffect(() => {
    let temp: any = countryList.filter((element) => {
      return element.countryCode === props.formikObj.values.countryCode;
    });
    console.log("temp", temp, props.formikObj);
    if (temp.length > 0 && temp !== undefined) setCountryCode(temp[0]);
  }, [props.formikObj.values.countryCode]);

  return (
    <>
      <div className={`${styles.phoneWrap}`}>
        <div className={`${styles.countryWrap}`}>
          <label htmlFor="">{t("phonenumber_countryNumber")}</label>
          <div
            className={`${styles.fieldWrap}`}
            onClick={() => setOpenCountryDrawer(true)}
          >
            <span className={`${styles.countryFlag}`}>
              <img
                // src={countryCode.flagLogo}
                srcSet={`https://flagcdn.com/w40/${countryCode?.code.toLowerCase()}.png 2x`}
                src={`https://flagcdn.com/w20/${countryCode?.code.toLowerCase()}.png`}
                alt=""
              />
            </span>
            <input type="text" readOnly value={countryCode?.countryCode} />
            <span className={`${styles.arrowIcon}`}></span>
          </div>
        </div>
        <FormControl variant="standard">
          <TextField
            type="number"
            label={t("phonenumber_placeholder")}
            id={props.id}
            placeholder={t("phonenumber_placeholder")}
            name={props.id}
            value={props.value}
            onChange={props.onChange}
            onBlur={(e) => {
              props.onBlur(e, props.id, "contact");
            }}
            error={props.error}
            helperText={props.helperText}
          />
        </FormControl>
      </div>
      <BottomSheet
        open={openCountryDrawer}
        title={t("phonenumber_selectCountryCode")}
        subText={t("phonenumber_selectCountryDialingCode")}
        size="large"
        setOpen={setOpenCountryDrawer}
      >
        <div className="bs-form">
          <div className={`${styles.countryDrawer}`}>
            <div className="search-wrap">
              <span className="search-icon"></span>
              <input
                type="text"
                placeholder={t("phonenumber_searchPlaceholder")}
                value={searchValue}
                onInput={handleCountrySearch}
              />
              {searchValue && (
                <button className="close-btn" onClick={handleClearSearch}>
                  <i></i>
                </button>
              )}
            </div>
            <div className={`${styles.selectionWrap}`}>
              <ul className={`${styles.countryList}`}>
                {filterCountryList.map((item, index) => {
                  return (
                    <li key={index} className={`${styles.countryItem}`}>
                      <input
                        onChange={(e) => handleRadio(e, item)}
                        type="radio"
                        name={props.countryCodeId}
                        id={item.code}
                        checked={
                          item.code === props.formikObj.values.countryCode
                        }
                      />
                      <label htmlFor={item.code}>
                        <span className={`${styles.flag}`}>
                          <img
                            srcSet={`https://flagcdn.com/w40/${item.code.toLowerCase()}.png 2x`}
                            src={`https://flagcdn.com/w20/${item.code.toLowerCase()}.png`}
                            alt=""
                          />
                        </span>
                        <span className={`${styles.country}`}>{item.name}</span>
                        <span className={`${styles.countryCode}`}>
                          {item.countryCode}
                        </span>
                      </label>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </BottomSheet>
    </>
  );
}

export default PhoneNumber;
