import { Link } from "react-router-dom";
import Button from "src/components/common/button/Button";
import Header from "src/components/common/header/Header";
import BottomSheet from "src/components/bottom-sheet/BottomSheet";
import styles from "./style.module.scss";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getSessionKey, setSessionKey } from "src/helper/sessionServices";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

function SignupLanding() {
  const { i18n, t } = useTranslation(["SignupLanding"]);
  const lang = getSessionKey("selectedLangCode");
  const [openOnboardDrawer, setOpenOnboardDrawer] = useState(false);
  const [selectedOnboardType, setSelectedOnboardType] = useState("");
  const [bsBtnState, setBsBtnState] = useState(true);
  const navigate = useNavigate();
  const handleNavigate = () => {
    setSessionKey("userRole", JSON.stringify(selectedOnboardType));
    navigate("/signup/intro");
  };
  const handleRadio = (event: any) => {
    setSelectedOnboardType(event.target.id);
  };

  const handleOpenBottomSheet = () => {
    setOpenOnboardDrawer(true);
    setSelectedOnboardType("");
  };

  const handleCloseBottomSheet = () => {
    setOpenOnboardDrawer(false);
    setSelectedOnboardType("");
  };

  const handleUserRole = (userRole: string) => {
    setSessionKey("userRole", JSON.stringify(userRole));
    navigate("/signup/intro");
  };
  useEffect(() => {
    i18next.changeLanguage(lang);
  }, []);

  return (
    <>
      <header>
        <Header backLink={true}></Header>
      </header>
      <main>
        <div className="lyt-main typ-signup">
          <h1 className={`${styles.title}`}>{t("signuplanding_pgTitle")}</h1>
          <div className={`${styles.cardWrap}`}>
            <div className={`${styles.userCardList}`}>
              <div className={`${styles.journeyCard}`}>
                <button onClick={handleOpenBottomSheet}>
                  <span
                    className={`${styles.userIcon} ${styles.investor}`}
                  ></span>
                  <div className={`${styles.titleDescWrap}`}>
                    <strong>{t("signuplanding_investorTitle")}</strong>
                    <p>{t("signuplanding_investorDesc")}</p>
                  </div>
                  <span className={`${styles.arrowIcon}`}></span>
                </button>
              </div>
              <div className={`${styles.journeyCard}`}>
                <button onClick={() => handleUserRole("advisor")}>
                  <span
                    className={`${styles.userIcon} ${styles.advisor}`}
                  ></span>
                  <div className={`${styles.titleDescWrap}`}>
                    <strong>{t("signuplanding_advisorTitle")}</strong>
                    <p>{t("signuplanding_advisorDesc")}</p>
                  </div>
                  <span className={`${styles.arrowIcon}`}></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>
      <BottomSheet
        title={t("signuplanding_drawerTitle")}
        subText={t("signuplanding_drawerSubTitle")}
        primaryBtnText={t("signuplanding_drawerConfirmBtn")}
        size="medium"
        open={openOnboardDrawer}
        setOpen={handleCloseBottomSheet}
        btnDisabled={selectedOnboardType === ""}
        btnOnClick={handleNavigate}
      >
        <div className={`${styles.cardCont}`}>
          <div className={`${styles.cardWrap}`}>
            <div className={`${styles.journeyCard}`}>
              <input
                type="radio"
                id="individual_investor"
                name="onboardType"
                checked={selectedOnboardType === "individual_investor"}
                onChange={handleRadio}
              />
              <label htmlFor="individual_investor">
                <span
                  className={`${styles.userIcon} ${styles.investor}`}
                ></span>
                <div className={`${styles.titleDescWrap}`}>
                  <strong>{t("signuplanding_drawerNaturalTitle")}</strong>
                  <p>{t("signuplanding_drawerNaturalDesc")}</p>
                </div>
              </label>
            </div>
          </div>
          <div className={`${styles.cardWrap}`}>
            <div className={`${styles.journeyCard}`}>
              <input
                type="radio"
                id="legalrepresent"
                name="onboardType"
                checked={selectedOnboardType === "legalrepresent"}
                onChange={handleRadio}
              />
              <label htmlFor="legalrepresent">
                <span className={`${styles.userIcon} ${styles.company}`}></span>
                <div className={`${styles.titleDescWrap}`}>
                  <strong>{t("signuplanding_drawerLegalTitle")}</strong>
                  <p>{t("signuplanding_drawerLegalDesc")}</p>
                </div>
              </label>
            </div>
          </div>
        </div>
      </BottomSheet>
    </>
  );
}

export default SignupLanding;
