import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
import Header from "src/components/common/header/Header";
import { FormControl, TextField } from "@mui/material";
import Button from "src/components/common/button/Button";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import axios from "axios";
import { getSessionKey, setSessionKey } from "src/helper/sessionServices";
import AddClientCard from "src/components/add-client-card/AddClientCard";
export const StepContext = React.createContext<{
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
} | null>(null);

function ProfileAddClients({ currentStep }: { currentStep: number }) {
  const [step, setStep] = useState(currentStep);
  const { i18n, t } = useTranslation(["Advisor"]);
  const lang = getSessionKey("selectedLangCode");
  const navigate = useNavigate();

  const [cardCount, setCardCount] = useState(1);
  const [isProceedDisabled, setIsProceedDisabled] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [addClientButtonClicked, setAddClientButtonClicked] = useState(false);
  const [dataFromChild, setDataFromChild] = useState<Item[]>([]);
  type Item = {
    email: string;
  };

  useEffect(() => {
    i18next.changeLanguage(lang);
  }, []);

  const handleAddCard = () => {
    setCardCount((prevCount) => prevCount + 1);
    setIsDisabled(true);
    setAddClientButtonClicked(true);
    setIsProceedDisabled(false);
  };
  const handleConfirm = () => {
    setIsDisabled(false);
    setAddClientButtonClicked(false);
    setIsProceedDisabled(true);
  };
  const handleDataFromChild = (data: any) => {
    setDataFromChild((prevItems) => [...prevItems, data]);
    console.log(dataFromChild);
  };
  const handleClick = () => {
    // navigate("/confirm-client");
    // setSessionKey("addClientObj", JSON.stringify(dataFromChild));
    const payload: any = [];
    dataFromChild.map((item: any, index: any) => {
      payload.push({
        email: item,
        relationshipType: 0,
      });
    });

    axios
      .post(`${process.env.REACT_APP_API_URL}relation`, payload, {
        headers: {
          Authorization: `Bearer ${getSessionKey("authToken").accesstoken}`,
        },
      })
      .then((res) => {
        if (res.data.success) navigate("/acknowledgement");
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          navigate("/enter-pin");
        } else {
          console.error("Error fetching data:", error.message);
        }
      });
  };

  const handleSkipandExplore = () => {
    navigate("/impact");
  };

  return (
    <StepContext.Provider value={{ step, setStep }}>
      <header>
        <Header
          backLink={true}
          showProgress={true}
          currentStep={step}
          totalSteps={3}
        ></Header>
      </header>
      <main>
        <div className="lyt-main typ-btn-spacing">
          <section>
            <div className={`${styles.section}`}>
              <div className={`${styles.sectionHead}`}>
                <div className={`${styles.titleWrap}`}>
                  <h1 className={`${styles.pgTitle}`}>
                    {t("advisor_addClientTitle")}
                  </h1>
                  <button
                    type="button"
                    className="edit-btn"
                    onClick={handleSkipandExplore}
                  >
                    {t("advisor_skipBtn")}
                  </button>
                </div>
                <p className={`${styles.subtitle}`}>
                  {t("advisor_clientPgSubTitle")}
                </p>
              </div>
              <div className="sec-cont">
                <div className={`${styles.contWrap}`}>
                  <ul className={`${styles.cardList}`}>
                    {[...Array(cardCount)].map((_, index) => (
                      <li className={`${styles.cardItem}`} key={index}>
                        <AddClientCard
                          cardCount={index + 1}
                          onConfirm={handleConfirm}
                          onDataFromChild={handleDataFromChild}
                        />
                      </li>
                    ))}
                    <button
                      type="button"
                      disabled={isDisabled || addClientButtonClicked}
                      className={`${styles.addBtn}`}
                      onClick={handleAddCard}
                    >
                      <i className={`${styles.addIcon}`}></i>
                      {t("advisor_addClientBtn")}
                    </button>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <div className={`${styles.footWrap}`}>
            <Button
              btnStyle="primary"
              disabled={!isProceedDisabled}
              onClick={handleClick}
            >
              {t("advisor_proceedBtn")}
            </Button>
          </div>
        </div>
      </main>
    </StepContext.Provider>
  );
}

export default ProfileAddClients;
