import { useState, useEffect } from "react";
import styles from "./style.module.scss";
import InsightCard, {
  InsightCardData,
} from "src/components/insight-card/InsightCard";
import SwiperComponent from "src/components/common/swiper/SwiperComponent";
import Header from "src/components/common/header/Header";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { formatDate } from "src/helper/formatDate";
import { authToken, headers } from "src/helper/authToken";
import Loader from "src/components/common/loader/Loader";
import { useLoader } from "src/context/LoaderContext";
import { getSessionKey } from "src/helper/sessionServices";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

function InsightDetail() {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation(["Home"]);
  const lang = getSessionKey("selectedLangCode");
  const { loading, setLoading } = useLoader();
  const [insightData, setInsightData] = useState<InsightCardData[]>([]);
  const [insightDetail, setInsightDetail] = useState<InsightCardData>();
  //const history = useHistory()
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const insightId = searchParams.get("insightcode");
  useEffect(() => {
    i18next.changeLanguage(lang);
  }, []);

  useEffect(() => {
    // Make a GET request with the specified headers
    axios
      .get(
        `${process.env.REACT_APP_API_URL}auth/insight?lang_id=${
          getSessionKey("userData")?.lang_id || getSessionKey("selectedLang")
        }`,
        {
          headers: {
            Authorization: `Bearer ${
              getSessionKey("authToken") !== null
                ? getSessionKey("authToken").accesstoken
                : ""
            }`,
          },
        }
      )
      .then((response) => {
        setInsightData(response?.data?.data);
      })
      .catch((error) => {
        if (error.response.status === 403 || error.response.status === 401) {
          navigate("/enter-pin");
        } else {
          console.error("Error fetching data:", error.message);
        }
      });
  }, []);
  useEffect(() => {
    setLoading(true);
    // Make a GET request with the specified headers

    axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }auth/insight?insightcode=${insightId}&lang_id=${
          getSessionKey("userData")?.lang_id || getSessionKey("selectedLang")
        }`,
        {
          headers: {
            Authorization: `Bearer ${
              getSessionKey("authToken") !== null
                ? getSessionKey("authToken").accesstoken
                : ""
            }`,
          },
        }
      )
      .then((response) => {
        setInsightDetail(response?.data?.data[0]);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 403 || error.response.status === 401) {
          navigate("/enter-pin");
        } else {
          console.error("Error fetching data:", error.message);
        }
      });
  }, [insightId]);

  console.log("insightDetail", insightDetail);
  const getDownloadObj = {
    filename: insightDetail?.file_name,
    fileurl: insightDetail?.file_url,
    PrimaryText: "Download Insight",
  };
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <header>
            <Header
              backLink={true}
              actionItems={["download"]}
              detailData={getDownloadObj!}
            ></Header>
          </header>
          <main>
            <div className="lyt-main">
              <section>
                <div className="bs-section">
                  <div className="sec-cont">
                    <div className={`${styles.insightDetailWrap}`}>
                      <span className={`${styles.cardType}`}>
                        {insightDetail?.tag}
                      </span>
                      <div className={`${styles.contentWrap}`}>
                        <div className={`${styles.contHead}`}>
                          <span className={`${styles.date}`}>
                            {formatDate(insightDetail?.insightDate!)}
                          </span>
                          <h1 className={`${styles.insightTitle}`}>
                            {insightDetail?.headline}
                          </h1>
                        </div>
                        <div
                          className="cont-body"
                          dangerouslySetInnerHTML={{
                            __html: insightDetail?.description || "",
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              {insightData && insightData.length > 1 ? (
                <section>
                  <div className="bs-section typ-insight">
                    <div className="sec-head">
                      <h2 className="cm-sec-title typ-sm">
                        {t("home_moreInsights")}
                      </h2>
                    </div>
                    <div className="sec-cont">
                      <div className="bs-swiper typ-insight">
                        <SwiperComponent
                          swiperConfig={{
                            slidesPerView: "auto",
                            spaceBetween: 12,
                            observer: true,
                            cssMode: true,
                            breakpoints: {
                              768: {
                                spaceBetween: 20,
                                cssMode: false,
                              },
                              1280: {
                                cssMode: false,
                                spaceBetween: 24,
                              },
                            },
                          }}
                        >
                          {insightData
                            ?.filter((item) => item.insightcode != insightId)
                            .map((item, index) => {
                              return (
                                <div key={index} className="slide-item">
                                  <InsightCard {...item}></InsightCard>
                                </div>
                              );
                            })}
                          <div className="slide-item card-view-all">
                            <Link to="/insight-list">
                              <div className="title-wrap">
                                <span>{t("home_viewAllBtn")}</span>
                                <span>{t("home_insights")}</span>
                              </div>
                              <i></i>
                            </Link>
                          </div>
                        </SwiperComponent>
                      </div>
                    </div>
                  </div>
                </section>
              ) : null}
            </div>
          </main>
        </>
      )}
    </>
  );
}

export default InsightDetail;
